import { createSelector } from 'reselect';
import { groupBy, max, min } from 'lodash-es';
import { averagesValues, mapData, filterYards, sortYards, actionsValues } from '../utils';
import { getYards } from '../../Dashboard/selectors';

export const getWorkspaceData = state => state.workspace.data;
export const getIsWorkspaceFetched = state => state.workspace.isFetched;
export const getWorkspaceSearch = state => state.workspace.search;
export const getWorkspaceFilter = state => state.workspace.filter;
export const getWorkspaceSort = state => state.workspace.sort;
export const getYardActions = state => state.workspace.yardActions;

export const getPreparedWorkspaceData = data => {
    const groupedByDate = groupBy(data, 'createdAt');
    const todaysDataKey = max(Object.keys(groupedByDate));
    const yesterdaysDataKey = min(Object.keys(groupedByDate));
    const todaysGroupedData = groupBy(groupedByDate[todaysDataKey], 'yardId');
    const yesterdaysGroupedData = groupBy(groupedByDate[yesterdaysDataKey], 'yardId');

    return Object.keys(todaysGroupedData).map(mapData(todaysGroupedData, yesterdaysGroupedData));
};

const getGroupedWorkspaceData = (data, yards, yardActions, search, filter, sort) => {
    const yardsMap = new Map(yards.map(yard => [yard.id, yard]));

    let combinedData = data.map(item => {
        const yard = yardsMap.get(item.id);
        const yardActionsFiltered = yardActions.filter(yardActions => yardActions.yard_id === item.id);

        return {
            ...item,
            name: yard?.name ?? item.name,
            lat: yard?.lat,
            lng: yard?.lng,
            yardActions: yardActionsFiltered,
        };
    });

    if (!filter.activeOnly) {
        combinedData = [
            ...combinedData,
            ...yards.reduce((acc, yard) => {
                if (data.some(item => item.id === yard.id)) {
                    return acc;
                }

                return [
                    ...acc,
                    {
                        id: yard.id,
                        name: yard.name,
                        lat: yard.lat,
                        lng: yard.lng,
                    },
                ];
            }, []),
        ];
    }

    return combinedData.filter(filterYards(filter)).toSorted((a, b) => sortYards(a, b, sort));
};

export const getYardGroupedData = createSelector(
    getWorkspaceData,
    getYards,
    getYardActions,
    getWorkspaceSearch,
    getWorkspaceFilter,
    getWorkspaceSort,
    getGroupedWorkspaceData
);

export const getFilterNumber = filter => {
    const keysToCheck = [
        'regions',
        'syrupTank',
        'larvae',
        averagesValues.EMPTY_FRAMES_AVERAGE,
        averagesValues.BEE_FRAMES_AVERAGE,
        averagesValues.HONEY_FRAMES_AVERAGE,
        averagesValues.BROOD_FRAMES_AVERAGE,
        actionsValues.FEED_ACTION,
        actionsValues.VISIT_ACTION,
        actionsValues.TRANSPORT_ACTION,
    ];

    return Object.keys(filter).reduce((acc, key) => {
        if (key === 'regions' && filter[key]?.length) {
            return acc + filter[key].length;
        }

        if (['syrupTank', 'larvae'].includes(key) && (filter[key].min > 0 || filter[key].max < 100)) {
            return acc + 1;
        }

        if (['activeOnly'].includes(key) && filter[key]) {
            return acc + 1;
        }

        if (keysToCheck.slice(5).includes(key) && filter[key].value) {
            return acc + 1;
        }

        if (
            (key === actionsValues.VISIT_ACTION ||
                key === actionsValues.FEED_ACTION ||
                key === actionsValues.TRANSPORT_ACTION) &&
            (filter[key].activeNow || filter[key].pastWeek || filter[key].moreThanAWeek)
        ) {
            return acc + 1;
        }

        return acc;
    }, 0);
};
