import React from 'react';
import dayjs from 'dayjs';
import constants from 'appConstants';

const getDefaultDiffText = ({ diff, date = null }) => {
    let endString =
        diff === 0 ? (
            'Today'
        ) : (
            <>
                {diff} day{diff === 1 ? '' : 's'} ago
            </>
        );

    if (date) {
        endString = `Last syrup fill: ${new Date(date).toLocaleDateString()}`;
    }

    return <div>{endString}</div>;
};

const getFrameTooltip = ({ frame, frameInfo, isBeeCountMode, showFramesHistory }) => {
    if (!frame?.frameId) {
        return null;
    }

    if (frame?.type === constants.FRAME_TYPES.FEEDER && !!frame?.previousUpdateDate) {
        const diff = dayjs().startOf('day').diff(dayjs(frame.lastUpdated).startOf('day'), 'day');
        return getDefaultDiffText({ diff, date: frame.lastUpdated });
    }

    const scanTimestampA = (isBeeCountMode ? frame.sideA?.beeCount?.scanTimestamp : frame.sideA?.scanTimestamp) ?? 0;
    const scanTimestampB = (isBeeCountMode ? frame.sideB?.beeCount?.scanTimestamp : frame.sideB?.scanTimestamp) ?? 0;
    const previousScanTimestampA =
        (isBeeCountMode ? frame.previousSideA?.beeCount?.scanTimestamp : frame.previousSideA?.scanTimestamp) ?? 0;
    const previousScanTimestampB =
        (isBeeCountMode ? frame.previousSideB?.beeCount?.scanTimestamp : frame.previousSideB?.scanTimestamp) ?? 0;
    const latestScan = showFramesHistory
        ? Math.max(previousScanTimestampA, previousScanTimestampB)
        : Math.max(scanTimestampA, scanTimestampB);

    if (!latestScan) {
        return;
    }

    const diff = dayjs().startOf('day').diff(dayjs(latestScan).startOf('day'), 'day');

    return getDefaultDiffText({ idx: frame.frameIdx, diff, frameInfo });
};

export default getFrameTooltip;
