import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'local-storage-fallback';
import Tooltip from '@beewise/tooltip';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils/build/lib/comparison';
import { getCurrentBhomes } from 'components/views/BeeHome/selectors';
import { fetchHives } from 'components/views/BeeHome/actions';
import Select from '@beewise/select';
import { fetchBhomesBroodFrameAmount, fetchAggregatedMessages, fetchAggregatedTemperature } from './actions';
import { getGraphData } from './selectors';
import {
    getBhomeSelectOptions,
    getPlotOptions,
    getReportSelectOptions,
    getStyleReportData,
    generateReportData,
    reportKeys,
    REPORTS_ENUM,
    ZOOM_LEVEL_ENUM,
    bhomeKey,
    hiveKey,
    zoomLevelKey,
    reportKey,
} from './utils';
import Chart from './components/Chart';
import HoneyChart from './components/HoneyChart';
import useMeasureSystem from './useMeasureSystem';
import { getIsSuperAdmin } from '../Admin/selectors';

import './Reports.scss';

const Reports = () => {
    const dispatch = useDispatch();

    const [bhome, setBhome] = useState(JSON.parse(storage.getItem(bhomeKey)) || {});
    const [hive, setHive] = useState(+storage.getItem(hiveKey) || null);
    const [zoomLevel, setZoomLevel] = useState(storage.getItem(zoomLevelKey) || ZOOM_LEVEL_ENUM.MAX);
    const [report, setReport] = useState(storage.getItem(reportKey) || 'brood');
    const isSuperAdmin = useSelector(getIsSuperAdmin);

    const graphData = useSelector(getGraphData, arrayOfObjectsShallowEqual);
    const temperature = useSelector(state => state.reports.aggregatedTemperature, arrayOfObjectsShallowEqual);
    const bhomes = useSelector(getCurrentBhomes, arrayOfObjectsShallowEqual);
    const bhomeOptions = useMemo(() => getBhomeSelectOptions(bhomes), [bhomes]);

    const measureSystem = useMeasureSystem();

    useEffect(() => {
        dispatch(fetchHives());
        dispatch(fetchBhomesBroodFrameAmount());
        dispatch(fetchAggregatedMessages());
        dispatch(fetchAggregatedTemperature());
    }, [dispatch]);

    useEffect(() => {
        if (report === REPORTS_ENUM.TEMPERATURE) {
            setHive(null);
            storage.setItem(hiveKey, null);
        } else if (report !== REPORTS_ENUM.TEMPERATURE && zoomLevel === ZOOM_LEVEL_ENUM.DAY) {
            setZoomLevel(ZOOM_LEVEL_ENUM.WEEK);
        }
    }, [report, zoomLevel]);

    const handleBhomeSelected = useCallback(
        (e, data) => {
            const bhome = bhomes.find(bhome => bhome.id === data.value);
            if (!bhome) {
                setHive(null);
                setBhome({});
                storage.setItem(hiveKey, null);
                storage.setItem(bhomeKey, JSON.stringify({}));
            } else {
                setBhome(bhome);
                storage.setItem(bhomeKey, JSON.stringify(bhome));
            }
        },
        [bhomes]
    );

    const handleReportSelected = useCallback((e, { value }) => {
        setReport(value);
        storage.setItem(reportKey, value);
    }, []);

    const reportRawData = useMemo(
        () => (report === 'temperature' ? temperature : graphData),
        [report, temperature, graphData]
    );

    // get graphs by report name and filter which do not require super admin access unless user has it
    const graphsDisplayed = useMemo(
        () =>
            getStyleReportData(measureSystem)[report]?.filter(
                graph => isSuperAdmin || (!isSuperAdmin && !graph.isSuperAdminAccess)
            ),
        [measureSystem, report, isSuperAdmin]
    );

    const dataOptions = useMemo(
        () =>
            generateReportData({
                measureSystem,
                data: reportRawData,
                isSuperAdmin,
                zoomLevel,
                bhome,
                report,
                hive,
            }),
        [measureSystem, reportRawData, isSuperAdmin, zoomLevel, bhome, report, hive]
    );

    const showPlot = useMemo(
        () =>
            dataOptions &&
            bhomeOptions.length &&
            dataOptions.some(row => {
                const keys = Object.values(reportKeys[report]);
                return keys.find(key => row[key]);
            }),
        [bhomeOptions, dataOptions, report]
    );

    const plotOptions = useMemo(() => getPlotOptions(zoomLevel, dataOptions), [zoomLevel, dataOptions]);

    const selectOptions = useMemo(
        () =>
            bhomeOptions.map(bhome => ({
                ...bhome,
                content: (
                    <Tooltip text={bhome.text}>
                        <div>{bhome.text}</div>
                    </Tooltip>
                ),
            })),
        [bhomeOptions]
    );

    return (
        <div className="reports">
            <header className="reports-header">
                <Select
                    className="reports-select"
                    options={getReportSelectOptions()}
                    value={report}
                    onChange={handleReportSelected}
                />
                <Select
                    placeholder="All Beehomes"
                    className="reports-bhome-select"
                    options={selectOptions}
                    disabled={!bhomeOptions.length}
                    value={bhome.id || 'all'}
                    onChange={handleBhomeSelected}
                />
            </header>
            {report !== 'honey' ? (
                <Chart
                    report={report}
                    graphsDisplayed={graphsDisplayed}
                    zoomLevel={zoomLevel}
                    dataOptions={dataOptions}
                    plotOptions={plotOptions}
                    showPlot={!!showPlot}
                />
            ) : (
                <HoneyChart />
            )}
        </div>
    );
};

export default Reports;
