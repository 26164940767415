import { get } from 'lodash-es';
import { ON_IN_PROGRESS_ACTIVITIES_CANCEL, ON_MESSAGE_UPDATE, ON_NEW_MESSAGE_RECEIVE } from 'actionTypes';
import constants from 'appConstants';
import hash from 'object-hash';
import { SET_CURRENT_BHOME } from 'components/views/BeeHome/actionTypes';
import {
    FETCH_ACTIVITIES,
    CHANGE_ACTIVITES_POSITION_STATE,
    ADD_UNSAVED_ACTIVITIES,
    REMOVE_UNSAVED_ACTIVITIES,
    CLEAR_UNSAVED_ACTIVITIES,
    SET_ACTION_MODE,
    SET_ENTRANCES_TO_OPERATE,
    SET_HALLWAY_VIDEO_URL,
    FETCH_MODE_IN_PROGRESS,
} from '../actionTypes';
import { CHANGE_HIVE_STATUSES } from '../../Hives/actionTypes';

const initialState = {
    activity: {},
    unsavedActivities: [],
    modeInProgress: [],
    actionMode: null,
    isFetched: false,
    newMessageReceived: false,
    entrancesToOperate: [],
    hallwayVideo: {
        url: null,
    },
};

const activity = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVITIES.success:
            return {
                ...state,
                activity: {
                    inProgress: action.data.messages.inProgress,
                    done: action.data.messages.done,
                },
                isFetched: true,
            };
        case FETCH_MODE_IN_PROGRESS.success:
            return {
                ...state,
                modeInProgress: [...state.modeInProgress, action.data.message],
            };
        case ON_NEW_MESSAGE_RECEIVE:
            if (
                action?.payload?.messageResult?.command === constants.COMMANDS.MOVE_TO_VISIT_POSITION ||
                action?.payload?.messageResult?.command === constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION ||
                action?.payload?.messageResult?.command === constants.COMMANDS.END_TRANSPORTATION_MODE ||
                action?.payload?.messageResult?.command === constants.COMMANDS.END_VISIT_MODE
            ) {
                return {
                    ...state,
                    modeInProgress: [...state.modeInProgress, action.payload.messageResult],
                };
            } else if (action?.payload?.messageResult?.from === 'technician') {
                return state;
            }

            return {
                ...state,
                activity: {
                    ...state.activity,
                    inProgress: [...(state.activity.inProgress || []), action.payload.messageResult],
                },
                newMessageReceived: true,
            };
        case ON_IN_PROGRESS_ACTIVITIES_CANCEL:
            return {
                ...state,
                activity: {
                    ...state.activity,
                    inProgress: state.activity.inProgress?.filter(
                        activity => !get(action, 'payload.message.payload.messageIds', []).includes(activity.id)
                    ),
                },
            };
        case CHANGE_HIVE_STATUSES.success:
            return {
                ...state,
                activity: {
                    ...state.activity,
                    done: [...action.data.messages.messages, ...state.activity.done],
                },
            };
        case ON_MESSAGE_UPDATE:
            const { message } = action.payload;
            const activity = { ...state.activity };
            const inProgress = activity.inProgress.filter(activity => activity.id !== message.id);
            if (
                message.status === constants.MESSAGE_STATUS.SUCCESS ||
                message.status === constants.MESSAGE_STATUS.FAILED ||
                message.status === constants.MESSAGE_STATUS.PROCESSING ||
                message.status === constants.MESSAGE_STATUS.SENT ||
                message.status === constants.MESSAGE_STATUS.ACKNOWLEDGED
            ) {
                const updatedMessage = activity.inProgress.find(activity => activity.id === message.id);
                activity.done.unshift({
                    ...updatedMessage,
                    status: message.status,
                });
                activity.inProgress = inProgress;
                return {
                    ...state,
                    activity: {
                        ...state.activity,
                        ...activity,
                    },
                };
            }

            if (message.status === constants.MESSAGE_STATUS.CANCELED) {
                activity.inProgress = inProgress;
                return {
                    ...state,
                    activity: {
                        ...state.activity,
                        ...activity,
                    },
                };
            }

            activity.inProgress = activity.inProgress.map(activity => {
                if (activity.id === message.id) {
                    return {
                        ...activity,
                        status: message.status,
                    };
                }
                return activity;
            });

            return {
                ...state,
                activity: {
                    ...state.activity,
                    ...activity,
                },
            };
        case CHANGE_ACTIVITES_POSITION_STATE:
            return {
                ...state,
                activity: {
                    ...state.activity,
                    inProgress: action.payload,
                },
            };
        case ADD_UNSAVED_ACTIVITIES:
            return {
                ...state,
                unsavedActivities: [
                    ...state.unsavedActivities,
                    ...action.activities.filter(
                        activity => !state.unsavedActivities.map(hash).includes(hash(activity))
                    ),
                ],
            };

        case REMOVE_UNSAVED_ACTIVITIES:
            return {
                ...state,
                unsavedActivities: state.unsavedActivities.filter(
                    activity => !action.activities.map(hash).includes(hash(activity))
                ),
            };
        case CLEAR_UNSAVED_ACTIVITIES:
            return {
                ...state,
                unsavedActivities: [],
            };
        case SET_ENTRANCES_TO_OPERATE:
            return {
                ...state,
                entrancesToOperate: action.entrances,
            };
        case SET_CURRENT_BHOME:
            return {
                ...state,
                unsavedActivities: [],
                entrancesToOperate: [],
                actionMode: null,
            };
        case SET_ACTION_MODE:
            return {
                ...state,
                unsavedActivities: [],
                actionMode: action.mode,
            };
        case SET_HALLWAY_VIDEO_URL:
            return {
                ...state,
                hallwayVideo: {
                    ...state.hallwayVideo,
                    ...action.payload?.stream,
                },
            };
        default:
            return state;
    }
};

export default activity;
