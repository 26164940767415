import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getPlural } from 'utils';
import { toggleSelectedHive } from 'components/views/BeeHome/actions';
import SelectBadge from 'components/reusables/SelectBadge';
import ActionIcon from 'components/reusables/ActionIcon';
import { getSelectedHives } from 'components/views/Bhome/selectors';
import { getBhomeDefinedHives, getPendingMessages } from 'components/views/BeeHome/selectors';
import ActiveModes from './ActiveModes';
import useActionHandlers from '../hooks/useActionHandlers';
import { ACTIONS, getScanOptions, SUB_ACTION_TYPES } from '../hooks/useActionHandlers/utils';
import {
    ACTION_TYPES,
    areAllHivesHavingBeeCountActionInProgress,
    areAllHivesHavingFeedActionInProgress,
    areAllHivesHavingScanActionInProgress,
    areSelectedHivesHavingActionInProgress,
    FEED_ACTION_TYPE,
} from '../utils';
import './Actions.scss';

const Actions = ({ currentBhome }) => {
    const selectedHives = useSelector(getSelectedHives);
    const pendingMessages = useSelector(getPendingMessages, arrayOfObjectsShallowEqual);
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);

    const dispatch = useDispatch();

    const [activeMode] = Object.entries(currentBhome?.mode || {}).find(([, value]) => value) || [null];

    const handleUnselectAllHives = () => dispatch(toggleSelectedHive());

    const { endActionHandler, createAction } = useActionHandlers({ selectedHives, activeMode, handleUnselectAllHives });
    const areAllHivesHavingFeedAction = areAllHivesHavingFeedActionInProgress(hives, pendingMessages);
    const areAllHivesHavingBeeCountAction = areAllHivesHavingBeeCountActionInProgress(hives, pendingMessages);
    const areAllHivesHavingScanAction = areAllHivesHavingScanActionInProgress(hives, pendingMessages);
    const areSelectedHivesHavingFeedAction = areSelectedHivesHavingActionInProgress(
        hives,
        selectedHives,
        pendingMessages,
        FEED_ACTION_TYPE
    );
    const areSelectedHivesHavingBeeCountAction = areSelectedHivesHavingActionInProgress(
        hives,
        selectedHives,
        pendingMessages,
        ACTION_TYPES.COUNT_BEES
    );
    const areSelectedHivesHavingScanAction = areSelectedHivesHavingActionInProgress(
        hives,
        selectedHives,
        pendingMessages,
        ACTION_TYPES.FULL_SCAN
    );
    const handleScanOptionChange = actionType => option => {
        if (
            (option === SUB_ACTION_TYPES.BEE_SCAN && areAllHivesHavingBeeCountAction) ||
            (option === SUB_ACTION_TYPES.FULL_SCAN && areAllHivesHavingScanAction)
        ) {
            return;
        }
        createAction(actionType)(option);
    };

    return activeMode ? (
        <ActiveModes activeMode={activeMode} bhomeMode={currentBhome.mode} endActionHandler={endActionHandler} />
    ) : (
        <div className="actions">
            <p className="actions-title">
                {!selectedHives.length ? (
                    <b>Beehome actions</b>
                ) : (
                    <span className="actions-title">
                        <FontAwesomeIcon icon={faClose} onClick={handleUnselectAllHives} />
                        <b>{getPlural(selectedHives.length, 'hive')}</b> selected
                    </span>
                )}
            </p>
            <div className="actions-icons">
                {Object.entries(ACTIONS).map(([actionType, { name, subActions, icon, tooltipInfo }]) =>
                    subActions ? (
                        <SelectBadge
                            key={name}
                            options={getScanOptions(
                                subActions,
                                areAllHivesHavingBeeCountAction,
                                areAllHivesHavingScanAction,
                                areSelectedHivesHavingBeeCountAction,
                                areSelectedHivesHavingScanAction
                            )}
                            size="small"
                            icon={icon}
                            onChange={handleScanOptionChange(actionType)}
                            tooltipInfo={tooltipInfo}
                        />
                    ) : (
                        <ActionIcon
                            key={name}
                            name={name}
                            icon={icon}
                            onClick={createAction(actionType)}
                            tooltipInfo={tooltipInfo}
                            allHivesHaveFrames={areAllHivesHavingFeedAction}
                            disabled={
                                name === 'Feed' && (areAllHivesHavingFeedAction || areSelectedHivesHavingFeedAction)
                            }
                        />
                    )
                )}
            </div>
        </div>
    );
};

Actions.propTypes = {
    currentBhome: PropTypes.shape(),
};

export default Actions;
