import {
    CHANGE_YARD_FILTER,
    CLEAR_YARD_FILTER,
    FETCH_WORKSPACE_DATA,
    FETCH_CREATE_YARD_ACTION,
    FETCH_UPDATE_YARD_ACTION,
    FETCH_GET_YARD_ACTION,
    CHANGE_YARD_SORT,
} from '../actionTypes';

export const fetchWorkspaceData = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_WORKSPACE_DATA.default,
        url: '/bhomes/v4/workspace',
    },
});

export const changeYardFilter = filter => ({
    type: CHANGE_YARD_FILTER,
    filter,
});

export const clearYardFilter = () => ({
    type: CLEAR_YARD_FILTER,
});

export const changeYardSort = sort => ({
    type: CHANGE_YARD_SORT,
    sort,
});

export const fetchCreateYardAction = ({ body, resolver, toastText }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_YARD_ACTION.default,
        url: '/yards/actions',
        body,
        method: 'POST',
        resolver,
        toastText,
    },
});

export const fetchUpdateYardAction = ({ body, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_YARD_ACTION.default,
        url: '/yards/actions',
        body,
        method: 'PUT',
        resolver,
    },
});

export const fetchGetYardAction = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_GET_YARD_ACTION.default,
        url: '/yards/actions',
    },
});
