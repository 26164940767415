import React, { useState, forwardRef, memo } from 'react';
import Icon from '@beewise/icon';
import { noop, isUndefined } from 'lodash-es';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import YardTooltip from 'components/views/Workspace/components/Map/components/YardTooltip/YardTooltip';

import './YardMarker.scss';

import { updateMapBounds } from '../utils';

const TOOLTIP_SIZE = { width: 300, height: 240 };
const DEFAULT_PADDING = 8;
const BORDER_PADDING = 24;

export const YardMarkerIcon = forwardRef(
    (
        {
            yard,
            onClick,
            extraData,
            className,
            isNameMarker,
            onMouseEnter = noop,
            onMouseLeave = noop,
            onHover = noop,
            onLeave = noop,
            map,
        },
        ref
    ) => {
        const handleClick = () => {
            updateMapBounds(map, [yard]);
            onClick?.(yard);
        };

        const handleMouseEnter = () => {
            onMouseEnter();
            onHover();
        };

        const handleMouseLeave = () => {
            onMouseLeave();
            onLeave();
        };

        return (
            <div className="yard-marker-wrapper" role="presentation" onClick={handleClick} ref={ref}>
                {!isNameMarker && (
                    <div className="icon-wrapper">
                        <Icon type="blured-marker" />
                        <span className="yard-marker-bhomes-number">{yard.bhomes?.length}</span>
                    </div>
                )}
                <div
                    className={cx('yard-label', className)}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {extraData}
                    {yard.name}
                </div>
            </div>
        );
    }
);

YardMarkerIcon.propTypes = {
    yard: PropTypes.shape({
        name: PropTypes.string.isRequired,
        bhomes: PropTypes.arrayOf(),
        id: PropTypes.string.isRequired,
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    extraData: PropTypes.node,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    isNameMarker: PropTypes.bool,
    onHover: PropTypes.func,
    onLeave: PropTypes.func,
    map: PropTypes.shape(),
};

const YardMarker = ({
    yard,
    onClick,
    isSelected,
    handleCheckboxClick,
    isHovered,
    selectedIds,
    clickedYard,
    mapContainerRef,
    setHoveredYard,
    map,
}) => {
    const [tooltipPosition, setTooltipPosition] = useState('top center');

    const handleOnCheckboxClick = event => {
        event.stopPropagation();
        handleCheckboxClick(event, yard.id);
    };

    const handleMouseEnter = () => {
        setHoveredYard(yard.id);
    };

    const handleMouseLeave = () => {
        setHoveredYard(null);
    };

    const markerRef = node => {
        if (!node) {
            return;
        }
        const markerRect = node.getBoundingClientRect();
        const mapRect = mapContainerRef.current?.getBoundingClientRect();
        if (!markerRect || !mapRect) {
            return;
        }
        const spaceAboveMarker = markerRect.top - mapRect.top;
        const spaceBelowMarker = mapRect.bottom - markerRect.bottom;
        const spaceLeftOfMarker = markerRect.left - mapRect.left;
        const spaceRightOfMarker = mapRect.right - markerRect.right;
        let newPosition = 'top center';

        if (spaceAboveMarker >= TOOLTIP_SIZE.height + DEFAULT_PADDING) {
            newPosition = 'top center';
        } else if (spaceBelowMarker >= TOOLTIP_SIZE.height + DEFAULT_PADDING) {
            newPosition = 'bottom center';
        }

        if (spaceRightOfMarker >= TOOLTIP_SIZE.height + BORDER_PADDING) {
            newPosition = 'right';
        } else if (spaceLeftOfMarker >= TOOLTIP_SIZE.height + BORDER_PADDING) {
            newPosition = 'left';
        }

        if (newPosition !== tooltipPosition) {
            setTooltipPosition(newPosition);
        }
    };

    return isUndefined(selectedIds) ? (
        <YardMarkerIcon onClick={onClick} yard={yard} map={map} />
    ) : (
        <Tooltip text={<YardTooltip yard={yard} />} position={tooltipPosition} className="yard-tooltip-wrapper">
            <YardMarkerIcon
                map={map}
                ref={markerRef}
                yard={yard}
                className={cx({
                    selected: isSelected,
                    'selected-yard': selectedIds?.length,
                    'clicked-yard': clickedYard === yard.id,
                    hovered: isHovered,
                })}
                onClick={onClick}
                onHover={handleMouseEnter}
                onLeave={handleMouseLeave}
                extraData={
                    <div
                        className={cx('yard-label-checkbox', {
                            selected: isSelected,
                        })}
                        role="presentation"
                        onClick={handleOnCheckboxClick}
                    >
                        {!isSelected && <FontAwesomeIcon icon={faCircle} className="yard-label-checkbox-unselected" />}
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={cx('yard-label-checkbox-selected', {
                                hidden: !isSelected,
                                selected: isSelected,
                            })}
                        />
                    </div>
                }
            />
        </Tooltip>
    );
};

YardMarker.propTypes = {
    yard: PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(),
        id: PropTypes.string.isRequired,
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    handleCheckboxClick: PropTypes.func.isRequired,
    isHovered: PropTypes.bool,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    clickedYard: PropTypes.string,
    mapContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    setHoveredYard: PropTypes.func,
    map: PropTypes.shape(),
};

export default memo(YardMarker);
