import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { SelectField } from '@beewise/select-field';
import React, { useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { noop } from 'lodash-es';
import { getDoneMessagesWithHives, getPendingMessagesWithHives } from 'components/views/BeeHome/selectors';
import { cancelActions, fetchHistoryMessages } from 'components/views/BeeHome/actions';
import Icon from 'components/reusables/Icon';
import { FILTER_OPTIONS, ALL_ACTIONS, GAP, HEADERS, PANEL_WIDTH, HEADERS_HEIGHT } from './utils';
import MessageItem from './ActionListItem';
import ActionsHeader from './ActionsHeader';
import useVirtualizedList from './useVirtualizedList';
import { getCurrentBhomeId } from '../../../../selectors';
import './ActionPanel.scss';

const EmptyStateComponent = ({ style, name }) => (
    <div style={style} className="action-list__empty">
        <Icon icon="empty-box" />
        No actions in {name}
    </div>
);

EmptyStateComponent.propTypes = {
    style: PropTypes.string,
    name: PropTypes.string,
};

const ActionPanel = ({ onClose }) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(ALL_ACTIONS.value);
    const pendingMessages = useSelector(state => getPendingMessagesWithHives(state, filter));
    const historyMessages = useSelector(state => getDoneMessagesWithHives(state, filter), arrayOfObjectsShallowEqual);
    const bhomeId = useSelector(getCurrentBhomeId);

    const listData = useMemo(
        () => [
            HEADERS[0],
            ...(pendingMessages.length
                ? pendingMessages.map(it => ({ ...it, isCancelable: true }))
                : [{ isEmpty: true, name: 'queue' }]),
            HEADERS[1],
            ...(historyMessages.length ? historyMessages : [{ isEmpty: true, name: 'history' }]),
        ],
        [pendingMessages, historyMessages]
    );
    const handleCancelActions = messageIds =>
        dispatch(
            cancelActions({
                bhomeId,
                messageIds,
                toastText: 'Actions were canceled',
                resolver: () => {
                    dispatch(fetchHistoryMessages(bhomeId));
                },
            })
        );

    const handleCancelAll = () => handleCancelActions(pendingMessages.flatMap(({ data }) => data.messages));

    const { visibleStartIndex, listRef, getItemSize, setVisibleStartIndex } = useVirtualizedList({ data: listData });

    const renderRow = ({ index, style }) => {
        const styleProps = {
            ...style,
            top: style.top + (index - visibleStartIndex) * GAP,
            maxWidth: PANEL_WIDTH,
        };
        if (listData[index].isEmpty) {
            return <EmptyStateComponent key={index} style={styleProps} name={listData[index].name} />;
        }
        return listData[index]?.header ? (
            <ActionsHeader key={index} handleCancelAll={handleCancelAll} style={styleProps} {...listData[index]} />
        ) : (
            <MessageItem
                key={index}
                style={styleProps}
                handleCancel={listData[index].isCancelable ? handleCancelActions : noop}
                {...listData[index]}
            />
        );
    };

    return (
        <div className="right-panel-content action-panel">
            <header>
                {' '}
                <FontAwesomeIcon icon={faXmark} className="frame-panel-header-top-left-icon" onClick={onClose} />
                <h3>Beehome information</h3>
                <SelectField options={FILTER_OPTIONS} value={filter} onChange={setFilter} size="xsmall" />
            </header>
            <section>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            onItemsRendered={({ visibleStartIndex }) => setVisibleStartIndex(visibleStartIndex)}
                            ref={listRef}
                            height={height - HEADERS_HEIGHT}
                            itemCount={listData?.length}
                            itemSize={getItemSize}
                            width={width}
                            className="action-list"
                        >
                            {renderRow}
                        </List>
                    )}
                </AutoSizer>
            </section>
        </div>
    );
};

ActionPanel.propTypes = {
    onClose: PropTypes.func,
};

export default ActionPanel;
