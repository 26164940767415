import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { noop, capitalize } from 'lodash-es';
import {
    fetchCurrentBhome,
    fetchFillFeeders,
    fetchPendingMessages,
    fetchScanBeehomeBySlotRange,
    fetchSendCountBees,
    fetchHistoryMessages,
    modifyMode,
} from 'components/views/BeeHome/actions';
import constants from 'appConstants';
import { ACTION_END, ACTION_START } from 'components/views/Workspace/utils';
import { getCurrentBhome } from 'components/views/Bhome/selectors';
import { getBhomeDefinedHives, getPendingMessages } from 'components/views/BeeHome/selectors';
import { getPlural } from 'utils';
import { ACTION_TYPES, SUB_ACTION_TYPES } from './utils';
import { getHivesWithoutPendingMessages } from '../../utils';

export const selectFramesFromHives = ({ hives, selectedHives, type }) => {
    const hivesToScan = selectedHives.length ? hives.filter(hive => selectedHives.includes(hive.id)) : hives;

    const framesToScan = hivesToScan.flatMap(hive => hive.frames);

    return framesToScan.filter(frame => frame.type === type).map(({ place, rfid }) => ({ place, rfid }));
};

const FRAME_ACTION_HANDLERS = ({ frameAction, numberOfHives }) => {
    switch (frameAction) {
        case SUB_ACTION_TYPES.FULL_SCAN:
            return {
                action: fetchScanBeehomeBySlotRange,
                frameType: constants.FRAME_TYPES.COMB_FRAME,
                toastText: `Full Scan applied successfully to ${getPlural(numberOfHives, 'hive')}`,
            };
        case SUB_ACTION_TYPES.BEE_SCAN:
            return {
                action: fetchSendCountBees,
                frameType: constants.FRAME_TYPES.COMB_FRAME,
                toastText: `Bee Scan applied successfully to ${getPlural(numberOfHives, 'hive')}`,
            };
        case ACTION_TYPES.FEED:
            return {
                action: fetchFillFeeders,
                frameType: constants.FRAME_TYPES.FEEDER,
                toastText: `Feeding applied successfully to ${getPlural(numberOfHives, 'hive')}`,
            };
        default:
            return noop;
    }
};

const useActionHandlers = ({ selectedHives, activeMode, handleUnselectAllHives }) => {
    const dispatch = useDispatch();
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const pendingMessages = useSelector(getPendingMessages, shallowEqual);
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);

    const handleStartMode = mode =>
        dispatch(
            modifyMode({
                bhomeId: currentBhome.id,
                mode,
                action: ACTION_START,
                toastText: `${capitalize(mode)} applied successfully`,
                resolver: () => {
                    dispatch(fetchCurrentBhome(currentBhome.id));
                    dispatch(fetchHistoryMessages(currentBhome.id));
                },
            })
        );

    const endActionHandler = () =>
        dispatch(
            modifyMode({
                bhomeId: currentBhome?.id,
                mode: activeMode,
                action: ACTION_END,
                resolver: () => {
                    dispatch(fetchCurrentBhome(currentBhome?.id));
                    dispatch(fetchHistoryMessages(currentBhome.id));
                },
            })
        );

    const handleSendFrameAction = frameAction => {
        const allHives = [...hives[constants.STATIONS.A], ...hives[constants.STATIONS.B]];

        const hivesWithoutPendingMessages = getHivesWithoutPendingMessages({
            hives: selectedHives.length ? allHives.filter(hive => selectedHives.includes(hive.id)) : allHives,
            frameAction,
            pendingMessages,
        });

        if (!hivesWithoutPendingMessages.length) {
            return;
        }

        const { frameType, action, toastText } = FRAME_ACTION_HANDLERS({
            frameAction,
            numberOfHives: hivesWithoutPendingMessages.length,
        });

        const slots = selectFramesFromHives({
            hives: hivesWithoutPendingMessages,
            selectedHives,
            type: frameType,
        });

        dispatch(
            action({
                slots,
                bhomeId: currentBhome.id,
                options: { toastText },
                resolver: () => {
                    dispatch(fetchPendingMessages({ bhomeId: currentBhome.id }));
                },
            })
        );
        handleUnselectAllHives();
    };

    const createAction = actionName => {
        switch (actionName) {
            case ACTION_TYPES.SCAN:
                return handleSendFrameAction;
            case ACTION_TYPES.FEED:
                return () => handleSendFrameAction(ACTION_TYPES.FEED);
            case ACTION_TYPES.VISIT:
                return () => handleStartMode(constants.MODE.VISIT);
            case ACTION_TYPES.TRANSPORT:
                return () => handleStartMode(constants.MODE.TRANSPORTATION);
            default:
                return noop;
        }
    };

    return {
        endActionHandler,
        createAction,
    };
};

export default useActionHandlers;
