import React, { useState, useCallback, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import useGranot from 'utils/isGranot';
import { getCurrentCompanyId, getIsSuperAdmin } from 'components/views/Admin/selectors';
import { fetchAdminCompanyUpdate, fetchAllCompanies } from 'components/views/Admin/actions';
import { getUnreadAlertsIds, getActivityFailedAlerts } from 'components/reusables/Alerts/selectors';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '@beewise/icon';
import Button from '@beewise/button';
import Tooltip from '@beewise/tooltip';
import { SelectField } from '@beewise/select-field';
import isSignInOrUpView from 'utils/isSignInOrUpView';
import { changeAlertPaneState, fetchAppSettings } from 'actions';
import { getIsActivityShown, getIsAlertPaneShown, getIsLimitedViewCompany, getUser } from 'selectors';
import analytics, { analyticsConstants, getLabelString } from 'utils/analytics';
import { getIsBeehomeTrendsDataShown, getShowBeeMap } from 'components/views/BeeHome/selectors';
import AlertsList from 'components/reusables/Alerts/components/AlertsList';
import { fetchHives, fetchYards, setCurrentBhome } from 'components/views/BeeHome/actions';
import {
    fetchAllBhomesOnline,
    fetchCompanyLocations,
    fetchDashboardGridData,
} from 'components/views/Dashboard/actions';
import ActivityFailurePopover from './components/ActivityFailurePopover';
import { fetchCompanyAlerts } from '../Alerts/actions';

import logo from './beewise-logo.svg';

import './AppHeader.scss';
import { getCompany } from '../../views/Settings/actions';

const AppHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const unreadAlertsList = useSelector(getUnreadAlertsIds, shallowEqual);
    const user = useSelector(getUser, shallowEqual);
    const isAlertPaneShown = useSelector(getIsAlertPaneShown);
    const isActivityShown = useSelector(getIsActivityShown);
    const isBeehomeTrendsDataShown = useSelector(getIsBeehomeTrendsDataShown);
    const showBeeMap = useSelector(getShowBeeMap);
    const isSuperAdmin = useSelector(getIsSuperAdmin);
    const companies = useSelector(state => state.admin.companies, arrayOfObjectsShallowEqual);
    const [company, setCompany] = useState(useSelector(getCurrentCompanyId));
    const activityFailedAlerts = useSelector(getActivityFailedAlerts, arrayOfObjectsShallowEqual);
    const [activityFailurePopoverShown, setActivityFailurePopoverShown] = useState(true);
    const isGranot = useGranot();

    const isSignIn = isSignInOrUpView(location);
    const isBhomePage = location.pathname.includes('beehome');
    const isNewBhomePage = location.pathname.includes('bhome');
    const isLandingPage = location.pathname.includes('lp1');

    const isLimitedView = useSelector(getIsLimitedViewCompany);

    useEffect(() => {
        if (user?.companyId) {
            dispatch(fetchCompanyAlerts({ isUnread: true }));
        }
        if (user?.id) {
            dispatch(fetchAppSettings());
            dispatch(getCompany());
        }
    }, [dispatch, user?.companyId, user?.id]);

    useEffect(() => {
        if (isSuperAdmin) {
            dispatch(fetchAllCompanies());
        }
    }, [dispatch, isSuperAdmin]);

    const companiesOptions = useMemo(
        () =>
            companies.reduce((acc, company) => {
                if (company?.company_type_names?.includes('beekeeper') && company?.has_bhomes) {
                    acc.push({ label: company.name, value: company.id });
                }
                return acc;
            }, []),
        [companies]
    );
    const handleSuccessCallback = useCallback(() => {
        dispatch(
            fetchHives(hiveData => {
                if (!isBhomePage && !isNewBhomePage) {
                    return;
                }
                if (hiveData.bhomes?.length) {
                    const firstBhomeWithGps = hiveData.bhomes.find(bhome => bhome.gps);
                    dispatch(setCurrentBhome(firstBhomeWithGps));
                    const path = isBhomePage ? 'beehome' : 'bhome';
                    navigate(`/${path}/${firstBhomeWithGps.id}`);
                } else {
                    navigate('');
                    dispatch(setCurrentBhome({}));
                }
            })
        );
        dispatch(fetchDashboardGridData({ offset: 0, limit: 0, type: '' }));
        dispatch(fetchCompanyLocations());
        dispatch(fetchAllBhomesOnline());
        // dispatch(fetchRanches());
        dispatch(fetchYards());
    }, [dispatch, isBhomePage, navigate, isNewBhomePage]);

    const handleSelectCompany = useCallback(
        value => {
            setCompany(value);
            const company = companies.find(comp => comp.id === value);
            if (company) {
                dispatch(fetchAdminCompanyUpdate(value, handleSuccessCallback));
            }
        },
        [companies, dispatch, handleSuccessCallback]
    );

    const labelString = getLabelString(isBeehomeTrendsDataShown, isActivityShown, showBeeMap);

    const hasNewAlert = !!unreadAlertsList && !!unreadAlertsList.length;

    const goToSettings = useCallback(() => {
        analytics.fireNavigateEvent({
            action: analyticsConstants.EVENT_NAMES.settings,
            label: labelString,
        });

        navigate('/settings');
    }, [navigate, labelString]);

    const handleAlertPaneState = useCallback(
        state => () => {
            if (state) {
                analytics.fireNavigateEvent({
                    action: analyticsConstants.EVENT_NAMES.alerts,
                    label: `Has new alerts - ${hasNewAlert}`,
                });
            }
            dispatch(changeAlertPaneState(state));
        },
        [dispatch, hasNewAlert]
    );

    const handleNavLinkClick = useCallback(
        action => () => {
            analytics.fireNavigateEvent({
                action: analyticsConstants.EVENT_NAMES[action],
                label: labelString,
            });
        },
        [labelString]
    );

    const handleHideActivityFailurePopover = useCallback(() => {
        setActivityFailurePopoverShown(false);
    }, []);

    if (isLandingPage) {
        return null;
    }

    return (
        <header className="app-header">
            <div className="logo-container">
                <NavLink to="/">
                    <img className="logo" src={logo} alt="Beewise logo" />
                </NavLink>
                <div className="app-env beta">Beta</div>
            </div>
            {!isSignIn && (
                <>
                    <div className="nav-list">
                        <NavLink to="/">Dashboard</NavLink>
                        <NavLink to="/workspace">Workspace</NavLink>
                        <NavLink to="/bhome" className={cx({ active: isBhomePage })}>
                            Beehomes
                        </NavLink>
                        {/* <NavLink to="/reports" onClick={handleNavLinkClick(analyticsConstants.EVENT_NAMES.reports)}> */}
                        {/*    Reports */}
                        {/* </NavLink> */}
                        <Tooltip content="Coming soon" position="bottom center">
                            <span className="disabled">Reports</span>
                        </Tooltip>
                        <NavLink to="/settings" onClick={handleNavLinkClick(analyticsConstants.EVENT_NAMES.settings)}>
                            Settings
                        </NavLink>
                    </div>
                    <div className="main-menu">
                        {isSuperAdmin && (
                            <SelectField
                                className="main-menu-dropdown"
                                options={companiesOptions}
                                onChange={handleSelectCompany}
                                value={company}
                            />
                        )}
                        {!isGranot && !isLimitedView && (
                            <OutsideClickHandler
                                onOutsideClick={handleAlertPaneState(false)}
                                disabled={!isAlertPaneShown}
                            >
                                <div
                                    className={cx('notifications-bell', {
                                        'notifications-bell-active': isAlertPaneShown,
                                    })}
                                    role="presentation"
                                    onClick={handleAlertPaneState(!isAlertPaneShown)}
                                >
                                    <Icon type="notification" />
                                    {hasNewAlert && <div className="red-dot" />}
                                </div>
                                {isAlertPaneShown && <AlertsList onClose={handleAlertPaneState(false)} />}
                                {!!activityFailedAlerts.length && activityFailurePopoverShown && !isAlertPaneShown && (
                                    <ActivityFailurePopover onClose={handleHideActivityFailurePopover} />
                                )}
                            </OutsideClickHandler>
                        )}
                        <Button className="icon" iconType="user" iconClassName="user-white" onClick={goToSettings} />
                    </div>
                </>
            )}
        </header>
    );
};

export default AppHeader;
