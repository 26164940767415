import { compact, flatten } from 'lodash-es';
import constants from 'appConstants';
import { getActivityData, parseTime } from '../../utils';

export const getDataWithTimePerActivity = (activitiesToShow = [], currentActivity) =>
    activitiesToShow.reduce((acc, item) => {
        const activityData = getActivityData(item);

        // TODO: add as elseif any action that will be sent to machine as group (check for same comment)
        if (activityData && item.id !== currentActivity?.id) {
            const itemsCounted =
                item.slots ||
                item.feeders ||
                item?.sequences?.filter(
                    item =>
                        item.command === constants.COMMANDS.SCAN_FRAME ||
                        item.command === constants.COMMANDS.COUNT_BEES_FRAME ||
                        item.command === constants.COMMANDS.FILL_SYRUP ||
                        item.command === constants.COMMANDS.MOVE_FRAME
                ) ||
                compact(flatten(item.moves?.map(move => move.find(item => item.isUserMove)) ?? [])) ||
                [];
            const timePerOneAction = activityData.time;
            const estimatedTime =
                timePerOneAction * (item.command === constants.COMMANDS.THERMAL_SCAN_FULL ? 1 : itemsCounted.length);
            acc.data = [
                ...(acc.data || []),
                {
                    ...item,
                    itemsCounted,
                    estimatedTime: parseTime(estimatedTime),
                    activityData,
                },
            ];
            acc.estimatedGeneralTime = acc.estimatedGeneralTime
                ? acc.estimatedGeneralTime + estimatedTime
                : estimatedTime;
        }
        return acc;
    }, {});
