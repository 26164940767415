import React from 'react';
import PropTypes from 'prop-types';
import './Legend.scss';

const contentLegendData = [
    { label: 'Capped honey', class: 'capped-honey' },
    { label: 'Open honey', class: 'opened-honey' },
    { label: 'Pollen', class: 'pollen' },
    { label: 'Capped brood', class: 'brood-capped' },
    { label: 'Open brood', class: 'brood-open' },
    { label: 'Eggs', class: 'brood-egg' },
    { label: 'Bees', class: 'bees' },
    { label: 'Thermal', class: 'thermal' },
    { label: 'Previous thermal', class: 'previous-thermal' },
];

const LegendItem = ({ item }) => (
    <div className="legend-content-item">
        <div className={`legend-octagon ${item.class}`} />
        <div className="legend-content-item-label">{item.label}</div>
    </div>
);

const ContentLegend = () => (
    <div className="legend">
        {[...contentLegendData].map(item => (
            <LegendItem item={item} key={`cl-${item.class}`} />
        ))}
    </div>
);

LegendItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
        class: PropTypes.string,
    }),
};

export default ContentLegend;
