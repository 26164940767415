import { createSelector } from 'reselect';
import constants from 'appConstants';

const getIsLoggedIn = state => state.app.isLoggedIn;

export const getIsSuperAdmin = state =>
    state.app.user.role === constants.ROLES.SUPER_ADMIN ||
    state.app.user.role === constants.ROLES.TECHNICIAN ||
    state.app.user.role === constants.ROLES.SUPER_VIEWER;
const isAdmin = state => state.app.user.role === constants.ROLES.ADMIN;
export const getRoles = () => constants.ROLES;
export const getCurrentCompanyId = state => state.app.user.companyId;

export const getIsLoggedInAsSuperAdmin = createSelector(
    getIsLoggedIn,
    getIsSuperAdmin,
    (isLoggedIn, isSuperAdmin) => !!isLoggedIn && !!isSuperAdmin
);

export const isLoggedAsAdmin = createSelector(
    getIsLoggedIn,
    isAdmin,
    (isLoggedIn, isAdmin) => !!isLoggedIn && !!isAdmin
);
