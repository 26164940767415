import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getCurrentBhomes, getFilteredBhomes } from 'components/views/BeeHome/selectors';
import { generateSelectOptions, groupVirtualizedListData, OTHER_LOCATIONS, ENTITY_LABELS } from './utils';
import { changeFilters } from '../../../BeeHome/actions';

export { OTHER_LOCATIONS };

const getByRanchOrYard = value => bhome => bhome.ranch_id === value || bhome.yard_id === value;
const getUnassignedBhomes = bhome => !bhome.ranch_id && !bhome.yard_id;

const getInitialFilter = bhome =>
    // eslint-disable-next-line
    bhome?.yard_id ? ENTITY_LABELS.YARDS : bhome.ranch_id ? ENTITY_LABELS.RANCHES : ENTITY_LABELS.UNASSIGNED;

const EMPTY_ARR = [];

const useFilters = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    const { entity: entityFilter, entityInstance: entityInstanceFilter } = useSelector(
        state => state.beehome.filters,
        shallowEqual
    );
    const bhomes = useSelector(getCurrentBhomes, arrayOfObjectsShallowEqual);
    const filteredBhomes = useSelector(getFilteredBhomes, arrayOfObjectsShallowEqual);

    const groupedDataLevel1 = useMemo(() => groupVirtualizedListData(bhomes), [bhomes]);
    const groupedDataLevel2 = useMemo(
        () => groupedDataLevel1.find(it => it.id === entityFilter)?.items ?? EMPTY_ARR,
        [groupedDataLevel1, entityFilter]
    );

    const entityOptions = useMemo(() => generateSelectOptions(groupedDataLevel1), [groupedDataLevel1]);
    const entityInstanceOptions = useMemo(() => generateSelectOptions(groupedDataLevel2), [groupedDataLevel2]);

    const handleSetEntityFilter = value => {
        const entityFilter = groupedDataLevel1.find(it => it.id === value);
        const entityInstanceFilter = entityFilter?.items?.[0]?.id;
        const filters = { entity: value, entityInstance: entityInstanceFilter };
        const bhome = bhomes.find(
            value !== OTHER_LOCATIONS ? getByRanchOrYard(entityInstanceFilter) : getUnassignedBhomes
        );
        dispatch(changeFilters(filters));
        navigate(`/bhome/${bhome?.id}`);
    };

    const handleSetEntityInstanceFilter = value => {
        dispatch(changeFilters({ entityInstance: value }));
        const bhome = bhomes.find(getByRanchOrYard(value));
        navigate(`/bhome/${bhome?.id}`);
    };

    // set filters on manual page reload and on company change
    useEffect(() => {
        if (!id) {
            return;
        }
        const bhome = bhomes.find(item => item.id === Number(id));
        if (!bhome) {
            return;
        }
        const locationTypeFilter = getInitialFilter(bhome);
        if (entityFilter === locationTypeFilter && entityInstanceFilter === (bhome?.yard_id || bhome.ranch_id)) {
            return;
        }
        const filters = {
            entity: locationTypeFilter,
            entityInstance: bhome?.yard_id || bhome.ranch_id,
        };

        dispatch(changeFilters(filters));
    }, [dispatch, id, bhomes, entityFilter, entityInstanceFilter]);

    return {
        entityOptions,
        entityInstanceOptions,
        filteredBhomes,
        entityFilter,
        entityInstanceFilter,
        handleSetEntityFilter,
        handleSetEntityInstanceFilter,
    };
};

export default useFilters;
