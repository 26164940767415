import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const CheckboxComponent = ({ className, reversed, ...props }) => (
  <Checkbox
    className={cx('beewise-checkbox', { reversed: !!reversed, [className]: !!className })}
    {...props}
  />
);

CheckboxComponent.propTypes = {
  className: PropTypes.string,
  reversed: PropTypes.bool
};

CheckboxComponent.defaultProps = {
  reversed: false
};

export default CheckboxComponent;
