import { useCallback, useEffect, useRef, useState } from 'react';
import { isUndefined } from 'lodash-es';
import { drawPolygons } from '../../../views/Bhome/components/Map/utils';

const initialMapState = {
    zoom: 2,
    center: { lat: 0, lng: 0 },
};

const useMap = ({ ranches }) => {
    const mapRef = useRef(null);

    const [mapOptions, setMapOptions] = useState(initialMapState);

    const handleMapChange = useCallback(({ center, zoom, bounds }) => {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();

        setMapOptions({
            center,
            zoom,
            bounds,
            boundsCoords: [sw.lng(), sw.lat(), ne.lng(), ne.lat()],
        });
    }, []);

    const handleMapLoad = ({ map }) => {
        mapRef.current = map;
    };

    const handleOnPlacesChange = e => {
        const lat = e?.[0]?.geometry?.location?.lat();
        const lng = e?.[0]?.geometry?.location?.lng();

        if (isUndefined(lat) || isUndefined(lng)) {
            return;
        }

        mapRef.current.setCenter({ lat, lng });
        mapRef.current.setZoom(12);
    };

    useEffect(() => {
        const polygons = drawPolygons({ ranches, mapRef, bounds: mapOptions.bounds });

        return () => polygons.forEach(poly => poly.setMap(null));
    }, [ranches, mapOptions.bounds]);

    return {
        mapRef,
        handleMapChange,
        mapOptions,
        handleMapLoad,
        handleOnPlacesChange,
    };
};

export default useMap;
