import { FETCH_THERMAL_SCAN_DATA } from '../actionTypes';

export const fetchThermalScanData = ({ bhomeId, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_THERMAL_SCAN_DATA.default,
        method: 'GET',
        url: `/bhomes/v4/${bhomeId}/frames/scans/thermal`,
        resolver,
    },
});
