import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { toggleMap } from 'actions';
import { saveCurrentMapState } from 'components/views/Dashboard/actions';
import {
    getBhomes,
    getCurrentMapState,
    getLocations,
    getBhomesOnline,
    getYards,
    getBeekeeperRanches,
} from 'components/views/Dashboard/selectors';
import { getIsInitialRender } from 'selectors';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { getMarkersWithClusters, updateMapBounds } from '../utils';
import { drawMarkers, drawLocationMarkers, drawYardMarkers } from './utils';
import MapControls from './MapControls';
import GoogleMap from './GoogleMap';
import EmptyIcon from './empty-location.svg';
import useBhomeLocationClusters from '../../hooks/useBhomeLocationClusters';

import './Map.scss';
import renderAdditionalMapMarkers from '../AdditionalMapMarkers';

const defaultCursor = 'url(https://maps.gstatic.com/mapfiles/openhand_8_8.cur), default';
const addNewLocationCursor = `url(${EmptyIcon}) 10 20, default`;

const Map = ({ onMapReady, maps, map, updateYardAndCoordsInfo, newLocationType, setNewLocationType }) => {
    const dispatch = useDispatch();
    const [clickListener, setClickListener] = useState(null);

    const locations = useSelector(getLocations, arrayOfObjectsShallowEqual);
    const bhomes = useSelector(getBhomes, arrayOfObjectsShallowEqual);
    const ranches = useSelector(getBeekeeperRanches, arrayOfObjectsShallowEqual);
    const isInitialRender = useSelector(getIsInitialRender);

    const yards = useSelector(getYards, arrayOfObjectsShallowEqual);
    const bhomesWithIoTStatuses = useSelector(getBhomesOnline, arrayOfObjectsShallowEqual);

    const bhomesOnline = useMemo(
        () =>
            bhomesWithIoTStatuses.reduce((acc, bhome) => {
                if (bhome?.connected) {
                    acc.add(bhome.id);
                }
                return acc;
            }, new Set()),
        [bhomesWithIoTStatuses]
    );

    const { bhomeMarkers, locationMarkers, bhomeMarkersForClusters, emptyMarkersForClusters } = useMemo(
        () => getMarkersWithClusters({ bhomes, bhomesOnline, locations }),
        [locations, bhomes, bhomesOnline]
    );

    const mapSavedState = useSelector(getCurrentMapState);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);

    const mapRef = useRef();
    const mapsRef = useRef();

    const [mapOptions, setMapOptions] = useState({});
    const [beehomeClusters, setBeehomeClusters] = useState([]);
    const [locationClusters, setLocationClusters] = useState([]);

    const handlePinClick = useCallback(() => {
        dispatch(toggleMap());
    }, [dispatch]);

    useEffect(
        () => () => {
            if (mapRef.current) {
                dispatch(
                    saveCurrentMapState(
                        {
                            lat: mapRef.current.getCenter().lat(),
                            lng: mapRef.current.getCenter().lng(),
                        },
                        mapRef.current.getZoom()
                    )
                );
            }
            if (clickListener) {
                map?.removeListener?.(clickListener);
            }
        },
        [clickListener, dispatch, map]
    );

    const { bhomeSupercluster, locationSupercluster } = useBhomeLocationClusters({
        bhomeMarkersForClusters,
        emptyMarkersForClusters,
        mapOptions,
        beehomeClusters,
        setBeehomeClusters,
        locationClusters,
        setLocationClusters,
    });

    useEffect(() => {
        const polygons = [];
        if (maps && map && ranches?.length) {
            ranches.forEach(item => {
                item.blocks.forEach(block => {
                    if (block.polygon) {
                        const polygon = new window.google.maps.Polygon({
                            paths: block.polygon,
                            strokeColor: '#b0d000',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: 'transparent',
                        });
                        polygons.push(polygon);
                        polygon.setMap(map);
                    }
                });
            });
        }
        return () => polygons.forEach(poly => poly.setMap(null));
    }, [map, maps, ranches]);

    useEffect(() => {
        if (isInitialRender) {
            return;
        }
        updateMapBounds(
            mapRef.current,
            bhomeMarkers.filter(marker => marker.bhome_ids.includes(currentBhome.id))
        );
    }, [bhomeMarkers, currentBhome.id, isInitialRender]);

    const handleMapChange = useCallback(({ center, zoom, bounds }) => {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        setMapOptions({
            center,
            zoom,
            bounds,
            boundsCoords: [sw.lng(), sw.lat(), ne.lng(), ne.lat()],
        });
    }, []);

    useEffect(() => {
        if (!newLocationType && mapRef.current) {
            mapRef.current.setOptions({ draggableCursor: defaultCursor });
        }
    }, [newLocationType]);

    const handleExitCreateMode = useCallback(() => {
        setNewLocationType(null);
    }, [setNewLocationType]);

    const handleClusterMarkerClick = useCallback((points = []) => {
        updateMapBounds(mapRef.current, points);
    }, []);

    const handleAddLocation = useCallback(
        type => () => {
            if (newLocationType === type) {
                handleExitCreateMode();
            } else {
                updateYardAndCoordsInfo({ editYard: null, createCoords: null });
                setNewLocationType(type);
                mapRef.current.setOptions({
                    draggableCursor: addNewLocationCursor,
                });
            }
        },
        [handleExitCreateMode, newLocationType, updateYardAndCoordsInfo, setNewLocationType]
    );

    const handleMapLoad = useCallback(
        ({ map, maps }) => {
            onMapReady(map, maps);

            mapRef.current = map;
            mapsRef.current = maps;

            const listener = map.addListener('click', e => {
                updateYardAndCoordsInfo({ createCoords: e.latLng.toJSON() });
            });

            setClickListener(listener);
            if (mapSavedState.center && mapSavedState.zoom) {
                map.setCenter(mapSavedState.center);
                map.setZoom(mapSavedState.zoom);
            } else if (currentBhome.id) {
                updateMapBounds(
                    mapRef.current,
                    bhomeMarkers.filter(marker => marker.bhome_ids.includes(currentBhome.id))
                );
            } else {
                updateMapBounds(map, [...bhomeMarkers, ...locationMarkers]);
            }
        },
        [
            onMapReady,
            mapSavedState.center,
            mapSavedState.zoom,
            currentBhome.id,
            updateYardAndCoordsInfo,
            bhomeMarkers,
            locationMarkers,
        ]
    );

    const handleYardClick = useCallback(
        data => {
            updateYardAndCoordsInfo({ editYard: data, createCoords: { lat: data.lat, lng: data.lng } });
        },
        [updateYardAndCoordsInfo]
    );

    const renderLocationMarkers = useCallback(
        () => drawLocationMarkers(locationClusters, locationSupercluster, handleClusterMarkerClick, mapRef.current),
        [handleClusterMarkerClick, locationClusters, locationSupercluster]
    );

    const renderBhomeMarkers = useCallback(
        () =>
            drawMarkers(beehomeClusters, bhomeSupercluster, handleClusterMarkerClick, mapRef.current, mapsRef.current),
        [beehomeClusters, bhomeSupercluster, handleClusterMarkerClick]
    );

    const renderYardMarkers = useCallback(
        () => drawYardMarkers(yards, handleYardClick, mapRef.current, mapsRef.current),
        [handleYardClick, yards]
    );

    useEffect(() => {
        if (bhomes.length && mapRef.current && mapsRef.current && isInitialRender) {
            const bounds = new mapsRef.current.LatLngBounds();
            bhomes.forEach(bhome => {
                if (bhome.gps) {
                    bounds.extend(new mapsRef.current.LatLng(bhome.gps.latitude, bhome.gps.longitude));
                }
            });
            mapRef.current.fitBounds(bounds);
        }
    }, [isInitialRender, bhomes]);

    return (
        <div
            className={cx('dashboard-map map-wrapper', {
                hybrid: mapRef.current && mapRef.current.mapTypeId === 'hybrid',
                roadmap: mapRef.current && mapRef.current.mapTypeId === 'roadmap',
            })}
        >
            <GoogleMap onLoad={handleMapLoad} onChange={handleMapChange}>
                {renderLocationMarkers()}
                {renderBhomeMarkers()}
                {renderYardMarkers()}
                {map?.zoom > 14 && renderAdditionalMapMarkers({ ranches })}
            </GoogleMap>
            {mapRef.current && mapsRef.current && (
                <MapControls
                    map={mapRef.current}
                    handleAddLocation={handleAddLocation}
                    isAddingLocation={!!newLocationType}
                />
            )}
            {mapRef.current && mapsRef.current && (
                <FontAwesomeIcon icon={faXmark} className="toggle-map-view" onClick={handlePinClick} />
            )}
        </div>
    );
};

Map.propTypes = {
    onMapReady: PropTypes.func,
    maps: PropTypes.shape(),
    map: PropTypes.shape(),
    updateYardAndCoordsInfo: PropTypes.func,
    newLocationType: PropTypes.string,
    setNewLocationType: PropTypes.func,
};

export default React.memo(Map);
