import React, { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { swapFrames } from '@beewise/utils';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import constants from 'appConstants';
import { useToggle, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Accordion from '@beewise/accordion';
import Button from '@beewise/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { getCurrentBeehomeId, getSelectedFramesForBalance } from 'components/views/BeeHome/selectors';
import { getUnsavedActivities, getActionMode } from 'components/reusables/RightPanel/selectors';
import {
    fetchFillFeeders,
    fetchScanBeehomeBySlotRange,
    setFrameToInspect,
    fetchChangeMarkedHives,
    fetchSendCountBees,
    setBalanceHiveMode,
} from 'components/views/BeeHome/actions';
import {
    clearUnsavedActivities,
    setActionMode,
    setEntrancesToOperate,
} from 'components/reusables/RightPanel/actions/activity.actions';
import { getBhomeFrames } from 'components/reusables/Alerts/selectors';
import { fetchMoveFramesV4 } from 'components/reusables/Hives/actions/move.frames.v4.actions';
import { UnsavedActivitiesList } from './utils';
import { prepareFrames } from '../utils';
import { DiscardConfirmationModal, FeedAllHivesConfirmationModal } from './UnsavedActivityModals';

import './UnsavedActivities.scss';

const UnsavedActivities = () => {
    const dispatch = useDispatch();

    const [isAccordionExpanded, toggleAccordion, , collapseAccordion] = useToggle(false);
    const [isDiscardModalShown, , showDiscardModal, hideDiscardModal] = useToggle(false);
    const [isFeedAllModalShown, , , hideFeedAllModal] = useToggle(false);

    const currentBhomeId = useSelector(getCurrentBeehomeId);
    const frames = useSelector(getBhomeFrames, arrayOfObjectsShallowEqual);
    const unsavedActivities = useSelector(getUnsavedActivities, shallowEqual);
    const actionMode = useSelector(getActionMode);
    const selectedFramesForBalance = useSelector(getSelectedFramesForBalance, shallowEqual);

    const resetUnsavedActivitiesAndActionMode = useCallback(() => {
        dispatch(clearUnsavedActivities());
        dispatch(setActionMode(null));
    }, [dispatch]);

    const handleAccordionClick = useCallback(() => {
        toggleAccordion();
    }, [toggleAccordion]);

    const handleDiscardButtonClick = useCallback(() => {
        showDiscardModal();
    }, [showDiscardModal]);

    const handleDiscardActivities = useCallback(() => {
        if (actionMode === constants.COMMANDS.BALANCE_HIVES) {
            dispatch(setBalanceHiveMode(false));
        }
        resetUnsavedActivitiesAndActionMode();
        dispatch(setEntrancesToOperate([]));
        dispatch(setFrameToInspect({}));
        hideDiscardModal();
    }, [actionMode, dispatch, hideDiscardModal, resetUnsavedActivitiesAndActionMode]);

    const executeFeeding = useCallback(() => {
        dispatch(fetchFillFeeders({ slots: unsavedActivities, bhomeId: currentBhomeId }));
    }, [currentBhomeId, dispatch, unsavedActivities]);

    const handleRunActivities = useCallback(() => {
        if (actionMode === constants.COMMANDS.FILL_FEEDER) {
            executeFeeding();
            resetUnsavedActivitiesAndActionMode();
        } else if (actionMode === constants.COMMANDS.SCAN) {
            dispatch(fetchScanBeehomeBySlotRange({ bhomeId: currentBhomeId, slots: unsavedActivities }));
            resetUnsavedActivitiesAndActionMode();
        } else if (actionMode === constants.COMMANDS.COUNT_BEES) {
            dispatch(fetchSendCountBees({ bhomeId: currentBhomeId, slots: unsavedActivities }));
            resetUnsavedActivitiesAndActionMode();
        } else if (actionMode === constants.COMMANDS.MARK_HIVES) {
            dispatch(fetchChangeMarkedHives(currentBhomeId, unsavedActivities));
            resetUnsavedActivitiesAndActionMode();
        } else if (constants.COMMANDS.BALANCE_HIVES) {
            dispatch(
                fetchMoveFramesV4(currentBhomeId, {
                    actions: unsavedActivities,
                    newFramesLayout: swapFrames(...Object.values(selectedFramesForBalance), frames).map(prepareFrames),
                })
            );
            dispatch(setBalanceHiveMode(false));
            resetUnsavedActivitiesAndActionMode();
        }
    }, [
        actionMode,
        currentBhomeId,
        dispatch,
        executeFeeding,
        frames,
        resetUnsavedActivitiesAndActionMode,
        selectedFramesForBalance,
        unsavedActivities,
    ]);

    document.addEventListener('dragFrame', collapseAccordion);

    const unsavedActivitiesAmount = unsavedActivities?.length;

    return (
        !!unsavedActivities.length && (
            <OutsideClickHandler onOutsideClick={collapseAccordion}>
                <div className="unsaved-activities">
                    <Accordion>
                        <Accordion.Title active={isAccordionExpanded}>
                            <div
                                className={cx('unsaved-activities-count', {
                                    'two-digits': unsavedActivitiesAmount > 9,
                                })}
                                role="presentation"
                                onClick={handleAccordionClick}
                            >
                                {unsavedActivitiesAmount}
                            </div>
                            <div
                                className="unsaved-activities-label"
                                role="presentation"
                                onClick={handleAccordionClick}
                            >
                                Unsaved activities
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    className={cx('accordion-arrow', {
                                        rotated: isAccordionExpanded,
                                    })}
                                />
                            </div>
                            <Button className="unsaved-activities-discard-button" onClick={handleDiscardButtonClick}>
                                Discard
                            </Button>
                            <Button className="unsaved-activities-run-button" onClick={handleRunActivities}>
                                Run
                            </Button>
                        </Accordion.Title>
                        <Accordion.Content active={isAccordionExpanded}>
                            <UnsavedActivitiesList actionMode={actionMode} unsavedActivities={unsavedActivities} />
                        </Accordion.Content>
                    </Accordion>
                    {isDiscardModalShown && (
                        <DiscardConfirmationModal
                            isOpen={isDiscardModalShown}
                            onCancel={hideDiscardModal}
                            onDiscard={handleDiscardActivities}
                        />
                    )}
                    {isFeedAllModalShown && (
                        <FeedAllHivesConfirmationModal
                            onSubmit={executeFeeding}
                            onCancel={hideFeedAllModal}
                            isOpen={isFeedAllModalShown}
                        />
                    )}
                </div>
            </OutsideClickHandler>
        )
    );
};

export default UnsavedActivities;
