import React from 'react';
import GroupMarker from '../GroupMarker';
import YardMarker from '../YardMarker';
import BhomeMarker from '../BhomeMarker';
import EmptyLocationMarker from '../EmptyLocationMarker';

export const drawMarkers = (clusters, supercluster, handleClusterMarkerClick, map, maps) =>
    clusters?.map(item =>
        item.properties?.cluster ? (
            <GroupMarker
                type="marker"
                key={item.id}
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                map={map}
                maps={maps}
                onClusterClick={handleClusterMarkerClick}
                cluster={item}
                supercluster={supercluster}
            />
        ) : (
            <BhomeMarker
                key={JSON.stringify(item?.marker?.bhome_ids)}
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                map={map}
                maps={maps}
                location={item.marker}
            />
        )
    );

export const drawYardMarkers = (
    yards,
    handleYardMarkerClick,
    map,
    maps,
    selectedIds,
    handleCheckboxClick,
    hoveredYards,
    clickedYard,
    mapContainerRef,
    setHoveredYard
) =>
    yards?.map(yard => {
        if (!yard.lat || !yard.lng) {
            return null;
        }

        const isSelected = selectedIds?.find(id => id === yard.id);
        const isHovered = hoveredYards?.find(id => id === yard.id);

        return (
            <YardMarker
                key={yard.id}
                lat={yard.lat}
                lng={yard.lng}
                map={map}
                maps={maps}
                yard={yard}
                isSelected={isSelected}
                onClick={handleYardMarkerClick}
                handleCheckboxClick={handleCheckboxClick}
                isHovered={isHovered}
                selectedIds={selectedIds}
                clickedYard={clickedYard}
                mapContainerRef={mapContainerRef}
                setHoveredYard={setHoveredYard}
            />
        );
    });

export const drawLocationMarkers = (clusters, supercluster, handleClusterMarkerClick, map, maps) =>
    clusters?.map(item =>
        item.properties?.cluster ? (
            <GroupMarker
                type="empty-marker"
                key={item.id}
                lat={item.geometry.coordinates[1]}
                lng={item.geometry.coordinates[0]}
                cluster={item}
                supercluster={supercluster}
                onClusterClick={handleClusterMarkerClick}
            />
        ) : (
            <EmptyLocationMarker
                key={item?.marker?.id}
                lat={item?.geometry?.coordinates[1]}
                lng={item?.geometry?.coordinates[0]}
                id={item?.marker?.id}
                name={item?.marker?.name}
                map={map}
                maps={maps}
            />
        )
    );
