import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@beewise/modal';
import Input from '@beewise/input';
import Checkbox from '@beewise/checkbox';
import Button from '@beewise/button';
import { getRoles } from 'components/views/Admin/selectors';
import { useToggle } from '@beewise/react-utils';

import { inviteUsers, getCompany } from '../../actions';
import { calculateUserRole } from '../../utils';

import './InvitationModal.scss';

const InvitationModal = ({ onClose, isOpen }) => {
    const [emails, setEmails] = useState('');
    const [isInvalid, setInvalid] = useState(false);
    const [canOperate] = useToggle(false);
    const dispatch = useDispatch();
    const roles = useSelector(getRoles);
    const invitationError = useSelector(state => state.settings.invitationError);

    useEffect(() => {
        if (!isOpen && emails) {
            setEmails('');
        }
    }, [isOpen, emails]);

    const dispatchInviteUsers = useCallback(() => {
        if (emails.split(',').every(item => item.match('^([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{2,4},?)+$'))) {
            setInvalid(false);
            const role = calculateUserRole(canOperate, roles);
            dispatch(inviteUsers(emails, role, () => dispatch(getCompany())));
        } else {
            setInvalid(true);
        }
    }, [dispatch, setInvalid, canOperate, emails, roles]);

    const handleEmail = useCallback(
        event => {
            setInvalid(false);
            setEmails(event.target.value.trim());
        },
        [setInvalid, setEmails]
    );

    const isInviteDisabled = () => !emails || isInvalid;

    const getError = () => (isInvalid ? 'Please enter correct emails separated by commas' : invitationError);

    return (
        <Modal header="Invite Members" isOpen={isOpen} onClose={onClose}>
            <div className="invitation">
                <div>
                    <span>Invite multiple users by filling their email addresses, separated by commas.</span>
                </div>
                {(isInvalid || invitationError) && <div className="error">{getError()}</div>}
                <div>
                    <Input value={emails} onChange={handleEmail} label="Email(s)" fluid />
                </div>
                <div>
                    <h3>Permissions</h3>
                    <div className="invitation-permissions">
                        <Checkbox label="Can inspect" name="see-reports" checked disabled readOnly />
                        <Checkbox label="Can operate" name="move-frames" checked disabled readOnly />
                    </div>
                </div>
                <div className="invitation-button-container">
                    <Button disabled={isInviteDisabled()} className="btn-primary" onClick={dispatchInviteUsers}>
                        Invite
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

InvitationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default InvitationModal;
