// move here reducer from beehome
import { FETCH_THERMAL_SCAN_DATA } from '../actionTypes';

const initialState = {
    thermalScanData: [],
};

const bhome = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_THERMAL_SCAN_DATA.success:
            return {
                ...state,
                thermalScanData: action.data,
            };
        default:
            return state;
    }
};

export default bhome;
