import Icon from 'components/reusables/Icon';
import Badge, { BADGE_TYPE, SIZE } from 'components/reusables/Badge';
import React from 'react';
import PropTypes from 'prop-types';

const FrameAction = ({ data: { isAllHives, hives }, handleCancelHiveAction, isCancelable }) => (
    <>
        <Icon icon="hive-workspace" />
        {isAllHives
            ? 'All hives'
            : hives.map((hive, index) => (
                  <Badge
                      type={BADGE_TYPE.DEFAULT}
                      size={SIZE.SMALL}
                      key={hive}
                      onCancel={isCancelable && handleCancelHiveAction(index)}
                  >
                      {hive}
                  </Badge>
              ))}
    </>
);

FrameAction.propTypes = {
    data: PropTypes.shape({
        isAllHives: PropTypes.bool,
        hives: PropTypes.arrayOf(PropTypes.string),
    }),
    handleCancelHiveAction: PropTypes.func,
    isCancelable: PropTypes.bool,
};

export default FrameAction;
