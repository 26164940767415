import React from 'react';
import PropTypes from 'prop-types';
import { loading } from '@beewise/react-utils';
import constants from 'appConstants';
import { get } from 'lodash-es';
import Checkbox from '@beewise/checkbox';
import { FETCH_USER_SETTINGS } from '../../actionTypes';

const KEYS_LABELS = {
    queenCell: 'Queen Cell',
    honeyLevel: `Honey container above ${constants.HARVESTING_LEVEL_ERROR}%`,
    syrupLevel: 'Syrup container below 20%',
};

const DISABLED_KEYS = ['honeyLevel'];

const Alerts = ({ userSettings = {}, handleCheckSettingsCheckbox }) => (
    <div className="settings-alerts">
        <div className="settings-alerts-header">Send me email notifications via email for the following events</div>
        <div className="settings-alerts-content">
            {Object.keys(get(userSettings, 'data.notifications', {})).map(key => (
                <Checkbox
                    key={key}
                    reversed
                    label={KEYS_LABELS[key]}
                    name={key}
                    disabled={DISABLED_KEYS.includes(key)}
                    checked={get(userSettings, `data.notifications.${key}`)}
                    onChange={handleCheckSettingsCheckbox(key, 'notifications')}
                />
            ))}
        </div>
    </div>
);

Alerts.propTypes = {
    userSettings: PropTypes.shape(),
    handleCheckSettingsCheckbox: PropTypes.func.isRequired,
};

export default loading(FETCH_USER_SETTINGS.default)(Alerts);
