import constants from '@beewise/constants';
import {
  createCloseHiveCommand,
  createMoveFrameCommand,
  getFrameData,
  createMakeSpaceCommand,
  createHomeXCommand,
  calculateLayout
} from './utils';

/**
 * Determines the input frame based on the largest position difference from its initial index.
 * @param {Object} param0 - Object containing movedFrames.
 * @param {Array} param0.movedFrames - Array of frames that have been moved.
 * @returns {Object} The input frame with the largest position difference, its index, and the difference.
 */
// const getInputFrame = ({ movedFrames }) =>
//   movedFrames.reduce(
//     (acc, frame, index) => {
//       const positionDifference = Math.abs(frame.initialFrameIndex - index);
//
//       if (positionDifference > acc.difference) {
//         acc.index = index;
//         acc.difference = positionDifference;
//         acc.frame = frame;
//       }
//
//       return acc;
//     },
//     { frame: null, index: null, difference: 0 }
//   );

const getInputFrame = ({ movedFrames }) => {
  const index = movedFrames.findIndex((frame) => frame.finalPositionX);
  const frame = movedFrames[index];

  return { frame, index };
};

/**
 * Generates a sequence of actions to shift the hive and hive layout with new coordinates.
 * @param {Object} param0 - Object containing movedFrames, settings, stations, and currentPartitionIndex.
 * @param {Array} param0.movedFrames - Array of frames that have been moved.
 * @param {Object} param0.settings - Settings for the hive operation.
 * @param {Object} param0.stations - Stations involved in the hive operation.
 * @param {number} param0.currentPartitionIndex - Index of the partition frame.
 * @param {boolean} param0.isFirstMove - Indicates if it is the first move of the frame.
 * @returns {Object} An object representing the shift hive command with its sequences and hive layout with new coordinates.
 */
export const getShiftHiveActionWithLayout = ({
  movedFrames,
  settings,
  stations,
  currentPartitionIndex,
  isFirstMove
}) => {
  const partitionFrame = movedFrames[currentPartitionIndex];
  const defaultFrameWidth = constants.DEFAULT_FRAME_WIDTH.COMB_FRAME ?? 0;
  const untuckDistanceX = settings?.sequences?.untuck?.x_untuck_distance ?? 0;
  const frameSpacingDistance = settings?.sequences?.untuck?.frame_spacing_distance ?? 0;
  const untuckDirection = -1;
  const combWidth = settings?.physical_measurements?.comb_width ?? defaultFrameWidth;

  const { index: inputFrameIndex, frame: inputFrame } = getInputFrame({ movedFrames });

  const moveDirection = inputFrame.place.position.x < inputFrame.finalPositionX ? 1 : -1;

  const homeXCommand = createHomeXCommand();

  const moveOutCommand = createMoveFrameCommand({
    frame: getFrameData({ frame: inputFrame, positionX: inputFrame.place.position.x }),
    place: {
      station: constants.STATIONS.SYSTEM_SLOT,
      position: {
        x: stations?.system_slot?.x
      }
    },
    shake: true,
    untuckDirection
  });

  const isNextToStatic =
    movedFrames[inputFrameIndex + 1].type === constants.FRAME_TYPES.STATIC_PARTITION;

  const makeSpaceCommand = createMakeSpaceCommand({
    frameType: inputFrame.type,
    place: {
      station: inputFrame.place.station,
      position: {
        x:
          moveDirection > 0
            ? isNextToStatic
              ? frame.finalPositionX + constants.DEFAULT_FRAME_WIDTH.STATIC_PARTITION / 2
              : inputFrame.finalPositionX + combWidth / 2
            : inputFrame.finalPositionX - combWidth / 2 - untuckDistanceX
      }
    },
    direction: -moveDirection
  });

  const moveBackCommand = createMoveFrameCommand({
    frame: getFrameData({
      frame: inputFrame,
      station: constants.STATIONS.SYSTEM_SLOT,
      positionX: stations?.system_slot?.x
    }),
    place: {
      station: inputFrame.place.station,
      position: {
        x: inputFrame.finalPositionX - frameSpacingDistance * moveDirection
      }
    },
    shake: false,
    untuckDirection: 0
  });

  const closeHiveCommand = createCloseHiveCommand({
    frame: getFrameData({
      frame: partitionFrame,
      positionX: partitionFrame.place.position.x - untuckDistanceX
    })
  });

  const sequences = isFirstMove ? [homeXCommand] : [];

  const shiftHiveSequence = [
    {
      command: constants.COMMANDS.SHIFT_HIVE,
      params: {
        sequences: [
          ...sequences,
          moveOutCommand,
          makeSpaceCommand,
          moveBackCommand,
          closeHiveCommand,
          homeXCommand
        ]
      }
    }
  ];

  return {
    actions: shiftHiveSequence,
    layout: calculateLayout({ movedFrames, inputFrame, inputFrameIndex, combWidth })
  };
};
