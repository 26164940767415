import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import constants from 'appConstants';
import { toJpeg } from 'html-to-image';
import JsPDF from 'jspdf';

export const EXPANDED_YARD_HEIGHT = 190;
export const SHIFT = 'Shift';
export const GRAPHS_LEVELS = {
    brood: {
        medium: 8,
        low: 4,
    },
    empty: {
        medium: 5,
        low: 2,
    },
    honey: {
        medium: 5,
        low: 2,
    },
    population: {
        medium: 10,
        low: 4,
    },
};
export const FILTER_AVERAGE_OPTIONS = [
    { value: 'gt', label: 'Above' },
    { value: 'lt', label: 'Below' },
];
export const averagesValues = {
    EMPTY_FRAMES_AVERAGE: 'emptyFramesAverage',
    BEE_FRAMES_AVERAGE: 'beeFramesAverage',
    HONEY_FRAMES_AVERAGE: 'honeyFramesAverage',
    BROOD_FRAMES_AVERAGE: 'broodFramesAverage',
};

export const actionsValues = {
    TRANSPORT_ACTION: constants.MODE.TRANSPORTATION,
    VISIT_ACTION: constants.MODE.VISIT,
    FEED_ACTION: 'feed',
};

export const getRowSize = () => EXPANDED_YARD_HEIGHT;

export const TRENDS = {
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
};

export const SORT_WORKSPACE_OPTIONS = [
    { value: 'location-north-to-south', label: 'Location - North to South' },
    { value: 'location-south-to-north', label: 'Location - South to North' },
];

export const ACTION_START = 'start';
export const ACTION_END = 'end';

export const NO_YARD_ID = 'Unknown';
const SYRUP_CAPACITY = 20;

const AMERICAN_LETTER_SIZE_SCALE = 1;
const AMERICAN_LETTER_SIZE = {
    width: 21.6 * AMERICAN_LETTER_SIZE_SCALE,
    height: 27.9 * AMERICAN_LETTER_SIZE_SCALE,
};
const PIXEL_TO_CM = 0.0264583333;

const processAverages = (bhome, acc) => {
    const updateAcc = (value, levels, accArray) => {
        if (value > levels.medium) {
            accArray[2] += 1;
        } else if (value > levels.low) {
            accArray[1] += 1;
        } else {
            accArray[0] += 1;
        }
    };
    updateAcc(bhome.averageBroodWithin2Weeks, GRAPHS_LEVELS.brood, acc.averageBrood);
    updateAcc(bhome.averageEmptyWithin2Weeks, GRAPHS_LEVELS.empty, acc.averageEmpty);
    updateAcc(bhome.averageHoneyWithin2Weeks, GRAPHS_LEVELS.honey, acc.averageHoney);
    updateAcc(bhome.averagePopulationWithin2Weeks, GRAPHS_LEVELS.population, acc.averagePopulation);
};

const getDefaultAccumulator = () => ({
    lastFeedTime: null,
    syrupRequired: 0,
    syrupLow: 0,
    totalSyrup: 0,
    larvaePresence: 0,
    totalHives: 0,
    totalBrood: 0,
    totalEmpty: 0,
    totalHoney: 0,
    totalPopulation: 0,
    totalThermalAssessmentDeadHives: 0,
    averagePopulation: [0, 0, 0],
    averageBrood: [0, 0, 0],
    averageEmpty: [0, 0, 0],
    averageHoney: [0, 0, 0],
    coordsState: null,
    totalSyrupVolume: 0,
});

const getTodaysData = data => {
    const recentCreatedAt = Math.max(...data.map(({ createdAt }) => createdAt));
    const recentData = data.filter(({ createdAt }) => recentCreatedAt === createdAt);

    return recentData.reduce((acc, bhome) => {
        if (bhome.lastFeedTime && bhome.lastFeedTime > acc.lastFeedTime) {
            acc.lastFeedTime = bhome.lastFeedTime;
        }
        if (bhome.totalAmountOfBroodHives) {
            acc.larvaePresence += bhome.totalAmountOfBroodHives;
        }
        if (bhome.totalThermalAssessmentDeadHives) {
            acc.totalThermalAssessmentDeadHives += bhome.totalThermalAssessmentDeadHives;
        }
        processAverages(bhome, acc);
        acc.syrupRequired += SYRUP_CAPACITY * ((100 - (bhome.syrupLevel ?? 0)) / 100);
        acc.syrupLow += (bhome.syrupLevel ?? 0) < 20 ? 1 : 0;
        acc.totalSyrup += Number.isNaN(bhome.syrupLevel) ? 0 : Number(bhome.syrupLevel);
        acc.totalBrood += bhome.averageBroodWithin2Weeks;
        acc.totalEmpty += bhome.averageEmptyWithin2Weeks;
        acc.totalHoney += bhome.averageHoneyWithin2Weeks;
        acc.totalPopulation += bhome.averagePopulationWithin2Weeks;
        acc.totalHives += bhome.markedHives?.length ?? 0;
        acc.totalSyrupVolume += SYRUP_CAPACITY;
        acc.coordsState = bhome.coordsState;
        return acc;
    }, getDefaultAccumulator());
};

const getYesterdaysData = (yesterdaysData, key) => {
    let yesterdayResultData = {
        yesterdayTotalHives: 0,
        yesterdayTotalBrood: 0,
        yesterdayTotalEmpty: 0,
        yesterdayTotalHoney: 0,
        yesterdayTotalPopulation: 0,
    };

    if (!isEmpty(yesterdaysData[key])) {
        yesterdayResultData = yesterdaysData[key].reduce((acc, bhome) => {
            if (bhome) {
                acc.yesterdayTotalBrood += bhome.averageBroodWithin2Weeks;
                acc.yesterdayTotalEmpty += bhome.averageEmptyWithin2Weeks;
                acc.yesterdayTotalHoney += bhome.averageHoneyWithin2Weeks;
                acc.yesterdayTotalPopulation += bhome.averagePopulationWithin2Weeks;
                acc.yesterdayTotalHives += bhome.markedHives?.length ?? 0;
            }

            return acc;
        }, yesterdayResultData);
    }

    return yesterdayResultData;
};

const checkFilterTrends = ({ totalValue, data, yesterdayTotalData, yesterdaysData, emptyFramesAverage }) => {
    const averageValue = totalValue ? Number(totalValue / (data?.length ?? 1)).toFixed(1) : 0;

    if (!averageValue) {
        return;
    }

    const yesterdayValue = yesterdayTotalData
        ? Number(yesterdayTotalData / (yesterdaysData?.length ?? 1)).toFixed(1)
        : 0;

    let populationTrend;

    if (emptyFramesAverage) {
        if (averageValue < yesterdayValue) {
            populationTrend = TRENDS.POSITIVE;
        } else if (averageValue > yesterdayValue) {
            populationTrend = TRENDS.NEGATIVE;
        }
    } else {
        if (averageValue > yesterdayValue) {
            populationTrend = TRENDS.POSITIVE;
        } else if (averageValue < yesterdayValue) {
            populationTrend = TRENDS.NEGATIVE;
        }
    }

    return populationTrend;
};

const filterActions = (filter, yard, actionType) => {
    if (!filter[actionType]) return true;

    const oneWeekAgo = dayjs().subtract(1, 'week');
    const conditions = [];

    if (filter[actionType].activeNow && filter[actionType].pastWeek) {
        const activeActions = yard.yardActions.filter(
            action => action.action === actionType && (!action.end_time || dayjs(action.end_time).isAfter(oneWeekAgo))
        );
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].activeNow && filter[actionType].moreThanAWeek) {
        const activeActions = yard.yardActions.filter(
            action => action.action === actionType && (!action.end_time || dayjs(action.end_time).isBefore(oneWeekAgo))
        );
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].pastWeek && filter[actionType].moreThanAWeek) {
        const activeActions = yard.yardActions.filter(
            action =>
                action.action === actionType &&
                (dayjs(action.end_time).isAfter(oneWeekAgo) || dayjs(action.end_time).isBefore(oneWeekAgo))
        );
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].activeNow) {
        const activeActions = yard.yardActions.filter(action => action.action === actionType && !action.end_time);
        if (!activeActions.length) conditions.push(true);
    } else if (filter[actionType].pastWeek) {
        const pastWeekActions = yard.yardActions.filter(
            action => action.action === actionType && dayjs(action.end_time).isAfter(oneWeekAgo)
        );
        if (!pastWeekActions.length) conditions.push(true);
    } else if (filter[actionType].moreThanAWeek) {
        const moreThanAWeekActions = yard.yardActions.filter(
            action => action.action === actionType && dayjs(action.end_time).isBefore(oneWeekAgo)
        );
        if (!moreThanAWeekActions.length) conditions.push(true);
    }

    return !conditions.length;
};

export const filterYards = filter => yard => {
    let passedFilter = true;

    if (
        passedFilter &&
        filter.regions?.length &&
        (!yard.regionId || !filter.regions.includes(yard.regionId)) &&
        (!yard.coordsState || !filter.regions.includes(yard.coordsState))
    ) {
        return false;
    }

    if (passedFilter && filter.syrupTank.min >= 0 && filter.syrupTank.max) {
        const averageSyrup = (yard.totalSyrup || 0) / (yard.data?.length || 1);
        passedFilter = averageSyrup >= filter.syrupTank.min && averageSyrup <= filter.syrupTank.max;
    }

    if (passedFilter && filter.larvae.min >= 0 && filter.larvae.max) {
        const larvaePercentage = Number(((yard.larvaePresence || 0) / (yard.totalHives || 1)) * 100).toFixed(0);
        passedFilter = larvaePercentage >= filter.larvae.min && larvaePercentage <= filter.larvae.max;
    }

    if (passedFilter && filter.recentWeekFeeding) {
        passedFilter = yard.lastFeedTime && dayjs().diff(dayjs(yard.lastFeedTime), 'day', true) < 7;
    }

    if (passedFilter && filter.moreThanAWeekFeeding) {
        passedFilter = yard.lastFeedTime && dayjs().diff(dayjs(yard.lastFeedTime), 'day', true) >= 7;
    }

    if (passedFilter && filter[averagesValues.EMPTY_FRAMES_AVERAGE].value) {
        const averageEmpty = yard.totalEmpty ? Number(yard.totalEmpty / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.EMPTY_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averageEmpty > filter[averagesValues.EMPTY_FRAMES_AVERAGE].value
                : averageEmpty < filter[averagesValues.EMPTY_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.BEE_FRAMES_AVERAGE].value) {
        const averagePopulation = yard.totalPopulation ? Number(yard.totalPopulation / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.BEE_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averagePopulation > filter[averagesValues.BEE_FRAMES_AVERAGE].value
                : averagePopulation < filter[averagesValues.BEE_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.HONEY_FRAMES_AVERAGE].value) {
        const averageHoney = yard.totalHoney ? Number(yard.totalHoney / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.HONEY_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averageHoney > filter[averagesValues.HONEY_FRAMES_AVERAGE].value
                : averageHoney < filter[averagesValues.HONEY_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.BROOD_FRAMES_AVERAGE].value) {
        const averageBrood = yard.totalBrood ? Number(yard.totalBrood / (yard.data?.length ?? 1)) : 0;
        passedFilter =
            filter[averagesValues.BROOD_FRAMES_AVERAGE].option === FILTER_AVERAGE_OPTIONS[0].value
                ? averageBrood > filter[averagesValues.BROOD_FRAMES_AVERAGE].value
                : averageBrood < filter[averagesValues.BROOD_FRAMES_AVERAGE].value;
    }

    if (passedFilter && filter[averagesValues.EMPTY_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalEmpty,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalEmpty,
            yesterdaysData: yard.yesterdaysData,
            emptyFramesAverage: true,
        });

        passedFilter = filter[averagesValues.EMPTY_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && filter[averagesValues.BEE_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalPopulation,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalPopulation,
            yesterdaysData: yard.yesterdaysData,
        });

        passedFilter = filter[averagesValues.BEE_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && filter[averagesValues.HONEY_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalHoney,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalHoney,
            yesterdaysData: yard.yesterdaysData,
        });

        passedFilter = filter[averagesValues.HONEY_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && filter[averagesValues.BROOD_FRAMES_AVERAGE].trend) {
        const trend = checkFilterTrends({
            totalValue: yard.totalBrood,
            data: yard.data,
            yesterdayTotalData: yard.yesterdayTotalBrood,
            yesterdaysData: yard.yesterdaysData,
        });

        passedFilter = filter[averagesValues.BROOD_FRAMES_AVERAGE].trend === trend;
    }

    if (passedFilter && actionsValues.TRANSPORT_ACTION) {
        passedFilter = filterActions(filter, yard, actionsValues.TRANSPORT_ACTION);
    }

    if (passedFilter && actionsValues.VISIT_ACTION) {
        passedFilter = filterActions(filter, yard, actionsValues.VISIT_ACTION);
    }

    if (passedFilter && actionsValues.FEED_ACTION) {
        passedFilter = filterActions(filter, yard, actionsValues.FEED_ACTION);
    }

    return passedFilter;
};

export const mapData = (todaysGroupedData, yesterdaysGroupedData) => key => {
    const resultData = getTodaysData(todaysGroupedData[key]);
    const yesterdayResultData = getYesterdaysData(yesterdaysGroupedData, key);

    if (!key || key === 'null') {
        return {
            id: NO_YARD_ID,
            name: 'Unknown',
            ...yesterdayResultData,
            ...resultData,
            regionId: null,
            regionName: null,
            data: todaysGroupedData[key],
            yesterdaysData: yesterdaysGroupedData[key],
        };
    }

    return {
        id: key,
        name: todaysGroupedData[key][0].yardName,
        regionId: todaysGroupedData[key][0].regionYardId,
        regionName: todaysGroupedData[key][0].regionYardName,
        ...yesterdayResultData,
        ...resultData,
        data: todaysGroupedData[key],
        yesterdaysData: yesterdaysGroupedData[key],
    };
};

export const sortYards = (a, b, sort) => {
    if (a.id === NO_YARD_ID) {
        return 1;
    }
    if (b.id === NO_YARD_ID) {
        return -1;
    }
    if (sort === SORT_WORKSPACE_OPTIONS[0].value) {
        return b.lat - a.lat;
    } else if (sort === SORT_WORKSPACE_OPTIONS[1].value) {
        return a.lat - b.lat;
    }
    return a.data?.length > b.data?.length ? -1 : 1;
};

export const getLatestEndTime = data => {
    const visitActions = data?.filter(item => item.action === constants.MODE.VISIT);

    if (!visitActions?.length) {
        return;
    }

    const latestVisit = visitActions.reduce((latest, current) => {
        const currentEndTime = new Date(current.end_time);
        return currentEndTime > new Date(latest.end_time) ? current : latest;
    });

    return latestVisit.end_time;
};

export const getYardData = yard => {
    const larvaePercentage = yard.larvaePresence
        ? Number(((yard.larvaePresence || 0) / (yard.totalHives || 1)) * 100).toFixed(0)
        : '-';
    const lastVisitTime = getLatestEndTime(yard.yardActions);
    const lastVisitTimeFormatted = lastVisitTime ? dayjs(lastVisitTime).format('D MMM') : 'N/A';
    const lastFeedTime = yard.lastFeedTime ? dayjs(yard.lastFeedTime).format('D MMM') : 'N/A';
    const averageSyrup = yard.totalSyrup ? ((yard.totalSyrup || 0) / (yard.data?.length || 1)).toFixed(0) : '-';
    const population = yard.totalPopulation ? Number(yard.totalPopulation / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayPopulation = yard.totalPopulation
        ? Number(yard.yesterdayTotalPopulation / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;
    const brood = yard.totalBrood ? Number(yard.totalBrood / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayBrood = yard.yesterdayTotalBrood
        ? Number(yard.yesterdayTotalBrood / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;
    const empty = yard.totalEmpty ? Number(yard.totalEmpty / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayEmpty = yard.yesterdayTotalEmpty
        ? Number(yard.yesterdayTotalEmpty / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;
    const honey = yard.totalHoney ? Number(yard.totalHoney / (yard.data?.length ?? 1)).toFixed(1) : null;
    const yesterdayHoney = yard.yesterdayTotalHoney
        ? Number(yard.yesterdayTotalHoney / (yard.yesterdaysData?.length ?? 1)).toFixed(1)
        : null;

    return {
        larvaePercentage,
        lastVisitTimeFormatted,
        lastFeedTime,
        averageSyrup,
        population,
        yesterdayPopulation,
        brood,
        yesterdayBrood,
        empty,
        yesterdayEmpty,
        honey,
        yesterdayHoney,
    };
};

const renderCell = value => (!value ? '-' : value.toFixed(1));

export const summaryColumns = [
    {
        field: 'name',
        headerName: 'Yard',
        minWidth: 240,
        filterable: false,
        sortable: false,
        flex: 1,
    },
    {
        field: 'totalThermalAssessmentDeadHives',
        headerName: 'Dead hives',
        minWidth: 240,
        filterable: false,
        sortable: false,
        flex: 1,
        valueGetter: params => renderCell(params.value),
    },
    {
        field: 'syrupRequired',
        headerName: 'Required syrup (gal)',
        minWidth: 240,
        filterable: false,
        sortable: false,
        flex: 1,
        valueGetter: params => renderCell(params.value),
    },
];

export const exportImage = async ref => {
    if (!ref.current) {
        return;
    }

    try {
        const filter = node => {
            const exclusionClasses = ['remove-from-pdf'];
            return !exclusionClasses.some(classname => node.classList?.contains(classname));
        };
        const dataUrl = await toJpeg(ref.current, { quality: 0.9, cacheBust: true, filter });
        const summaryWidth = ref.current.clientWidth * PIXEL_TO_CM;
        const summaryHeight = ref.current.clientHeight * PIXEL_TO_CM;

        const pdf = new JsPDF('p', 'cm', [AMERICAN_LETTER_SIZE.height, AMERICAN_LETTER_SIZE.width]);

        pdf.addImage(dataUrl, 'PNG', 0, 0, summaryWidth, summaryHeight);

        pdf.save('summary.pdf');
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error exporting image:', error);
    }
};

export const getBhomeIds = (yards, selectedIds, yardId) => {
    if (yardId) {
        const yard = yards.find(yard => yard.id === yardId);

        return yard ? yard.bhomes : [];
    }

    const selectedYards = yards.filter(yard => selectedIds.includes(yard.id));

    return selectedYards.flatMap(yard => yard.bhomes);
};

export const getYardIdsToUpdate = (selectedIds, yardActions, modes) =>
    selectedIds.filter(
        id =>
            !yardActions.some(
                yardAction => yardAction.yard_id === id && !yardAction.end_time && modes.includes(yardAction.action)
            )
    );

export const findYardActionIds = (yardActions, yardId) =>
    yardActions
        .filter(action => action.yard_id === yardId && !action.end_time)
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
        .map(action => action.id);

export const smoothScroll = (start, end, duration, listRef) => {
    const diff = end - start;
    let startTime;

    const scroll = timestamp => {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);

        listRef.current.scrollTo(start + diff * progress);

        if (progress < 1) {
            requestAnimationFrame(scroll);
        }
    };

    requestAnimationFrame(scroll);
};

export const getSelectedYardsWithFeedMode = ({ selectedIds, yardActions }) =>
    selectedIds.every(id => {
        const yardActiveAction = yardActions?.find(
            yardAction =>
                yardAction.yard_id === id && !yardAction?.end_time && yardAction.action === actionsValues.FEED_ACTION
        );

        return yardActiveAction;
    });

export const getSelectedActiveModes = ({ selectedIds, yardActions }) =>
    selectedIds.reduce((acc, id) => {
        const yardActiveAction = yardActions?.find(yardAction => yardAction.yard_id === id && !yardAction?.end_time);

        if (yardActiveAction) {
            acc.push(yardActiveAction?.action);
        }
        return acc;
    }, []);
