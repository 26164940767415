import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { noop } from 'lodash-es';
import Toggle from '@beewise/toggle';
import constants from 'appConstants';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import cn from 'classnames';
import { getCurrentBhome, getCurrentBhomeFrames } from 'components/views/BeeHome/selectors';
import useMarkedHives from 'utils/useMarkedHives';
import Station from './components/Station';

import './Hives.scss';

const Hives = ({ isModalMode = false, onHiveSelectModalMode = noop, selectedHiveModalMode }) => {
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const data = useSelector(getCurrentBhomeFrames, arrayOfObjectsShallowEqual);

    const [isBeeCountMode, setIsBeeCountMode] = useState(!isModalMode);

    const markedHives = useMarkedHives();

    return (
        <div className="hives hive-stations">
            <div className={cn('hive-stations-container', { modal: isModalMode })}>
                {!isModalMode && (
                    <Toggle
                        value={isBeeCountMode}
                        name="isBeeCountMode"
                        onChange={setIsBeeCountMode}
                        label="Bee Count"
                    />
                )}
                <div className="hive-stations-content">
                    <div className="hive-stations-lines">
                        <div className="hive-stations-line">
                            <div className="hive-stations-name">A</div>
                            <div className="hive-stations-frames">
                                <Station
                                    stationName={constants.STATIONS.A}
                                    layout={data}
                                    hives={markedHives}
                                    settings={currentBhome?.settings}
                                    isModalMode={isModalMode}
                                    onHiveSelectModalMode={onHiveSelectModalMode}
                                    selectedHiveModalMode={selectedHiveModalMode}
                                    isBeeCountMode={isBeeCountMode}
                                />
                            </div>
                        </div>
                        <div className="hive-stations-line">
                            <div className="hive-stations-name">B</div>
                            <div className="hive-stations-frames">
                                <Station
                                    stationName={constants.STATIONS.B}
                                    layout={data}
                                    hives={markedHives}
                                    settings={currentBhome?.settings}
                                    isModalMode={isModalMode}
                                    onHiveSelectModalMode={onHiveSelectModalMode}
                                    selectedHiveModalMode={selectedHiveModalMode}
                                    isBeeCountMode={isBeeCountMode}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="hive-syrup-container">
                        <span className="hive-syrup-text">{currentBhome?.sensors?.syrupLevel ?? 0}% Syrup</span>
                        <div
                            className="hive-syrup-level"
                            style={{
                                height: `${currentBhome?.sensors?.syrupLevel ?? 0}%`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Hives.propTypes = {
    isModalMode: PropTypes.bool,
    onHiveSelectModalMode: PropTypes.func,
    selectedHiveModalMode: PropTypes.shape(),
};

export default memo(Hives);
