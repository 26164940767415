import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLayoutSnapshot, setSnapshotToShow } from '../../actions';
import { getAreSnapshotsFetched, getIsHistoryLayoutSnapshotShown, getSnapshots } from '../../selectors';

import './HistorySlider.scss';

const HistorySlider = ({ bhomeId }) => {
    const ref = useRef();
    const [sliderValue, setSliderValue] = useState(0);
    const isHistoryLayoutSnapshotShown = useSelector(getIsHistoryLayoutSnapshotShown);
    const areSnapshotsFetched = useSelector(getAreSnapshotsFetched);
    const snapshots = useSelector(getSnapshots, arrayOfObjectsShallowEqual);
    const dispatch = useDispatch();

    useEffect(() => {
        if (bhomeId) {
            dispatch(fetchLayoutSnapshot(bhomeId));
        }
    }, [bhomeId, dispatch]);

    const handleSliderChange = useCallback(
        e => {
            const { value } = e.target;
            setSliderValue(value);
            if (!Number(value)) {
                dispatch(setSnapshotToShow(null));
                return;
            }

            const historyDate = dayjs.utc().subtract(value, 'day');
            const snapshot = snapshots.find(item => dayjs.utc(item.createdAt).isSame(historyDate, 'day'));
            if (snapshot) {
                dispatch(setSnapshotToShow(snapshot));
            }
        },
        [dispatch, snapshots]
    );

    useEffect(() => {
        if (!isHistoryLayoutSnapshotShown && Number(sliderValue)) {
            setSliderValue(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHistoryLayoutSnapshotShown]);

    return (
        <div className="hives-history-slider">
            <input
                disabled={!areSnapshotsFetched}
                ref={ref}
                max={30}
                type="range"
                defaultValue={0}
                onChange={handleSliderChange}
                value={sliderValue}
            />{' '}
            {Number(sliderValue) ? `${sliderValue} days ago` : 'Current'}
        </div>
    );
};

HistorySlider.propTypes = {
    bhomeId: PropTypes.number.isRequired,
};

export default HistorySlider;
