import React from 'react';
import PropTypes from 'prop-types';
import { get, groupBy } from 'lodash-es';
import { format } from 'timeago.js';
import constants from 'appConstants';
import { COMMANDS } from '@beewise/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCamera,
    faDroplet,
    faArrowRightFromLine,
    faQuestion,
    faFolderOpen,
    faTriangleExclamation,
    faOutdent,
    faIndent,
    faShelves,
    faBlockQuote,
    faScannerTouchscreen,
} from '@fortawesome/pro-light-svg-icons';

import './Panes.scss';

export const getActivityData = item => {
    const ActivitiesData = {
        [COMMANDS.SCAN]: {
            name: 'Scan frames',
            item: 'frame',
            time: 8.4,
        },
        [COMMANDS.COUNT_BEES]: {
            name: 'Bee count',
            item: 'frame',
            time: 5,
        },
        [COMMANDS.MOVE_FRAMES]: {
            name: 'Move frames',
            item: 'frame',
            time: 9.4,
        },
        [COMMANDS.FILL_FEEDER]: {
            name: 'Feed',
            item: 'hive',
            time: 8.5,
        },
        [COMMANDS.CONTRACT_HIVE]: {
            name: 'Contract hive',
            item: 'hive',
            time: 15,
        },
        [COMMANDS.EXPAND_HIVE]: {
            name: 'Expand hive',
            item: 'hive',
            time: 15,
        },
        [COMMANDS.BALANCE_HIVES]: {
            name: 'Balance hive',
            item: 'hive',
            time: 15,
        },
        [COMMANDS.SHIFT_HIVE]: {
            name: 'Shift hive',
            item: 'hive',
            time: 15,
        },
        [COMMANDS.THERMAL_SCAN_FULL]: {
            name: 'Thermal scan',
            item: 'hive',
            time: 15,
        },
        addCappedBroodFrame: {
            name: 'Add capped brood frame',
            description:
                'This colony has a small population. We recommend adding a capped brood frame to strengthen it.',
            time: null,
        },
        addHoneyFrame: {
            name: 'Add honey frame',
            description:
                'This colony has low food reserves. We recommend adding a honey frame to ensure its nourishment during the upcoming season.',
            time: null,
        },
        addPollenFrame: {
            name: 'Add pollen frame',
            description:
                'This colony is not building up its population properly. We recommend adding a pollen frame to ensure protein intakes and optimize the brood-rearing conditions.',
            time: null,
        },
        addFoundationFrame: {
            name: 'Add foundation frame',
            description:
                'While this colony is thriving, there is little expansion space. We recommend adding a foundation for drawing, letting the colony spread within the hive, and avoiding swarming.',
            time: null,
        },
        addEmptyCombFrame: {
            name: 'Add empty comb frame',
            description:
                'This colony fills up all its frames. We recommend adding an empty comb to allow further honey collection.',
            time: null,
        },
        removeEmptyFrame: {
            name: 'Remove empty frame',
            description:
                'This colony doesn’t make use of its full hive area. We recommend removing an empty frame to allow it to attend to the entire hive.',
            time: null,
        },
    };
    return ActivitiesData[item.command];
};

export const getActivityIcon = (
    // eslint-disable-next-line camelcase
    { command, status }
) => {
    if (status === constants.MESSAGE_STATUS.FAILED) {
        return faTriangleExclamation;
    }
    switch (command) {
        case constants.COMMANDS.SCAN:
        case constants.COMMANDS.COUNT_BEES:
            return faCamera;
        case constants.COMMANDS.FILL_FEEDER:
            return faDroplet;
        case constants.COMMANDS.MOVE_FRAMES:
            return faArrowRightFromLine;
        case constants.COMMANDS.CONTRACT_HIVE:
            return faOutdent;
        case constants.COMMANDS.EXPAND_HIVE:
            return faIndent;
        case constants.COMMANDS.SHIFT_HIVE:
            return faBlockQuote;
        case constants.COMMANDS.BALANCE_HIVES:
            return faShelves;
        case constants.COMMANDS.THERMAL_SCAN_FULL:
            return faScannerTouchscreen;
        default:
            return faQuestion;
    }
};

export const getActivityDescription = activity => {
    switch (activity.command) {
        case constants.COMMANDS.SCAN:
        case constants.COMMANDS.COUNT_BEES:
            const scannedFrames = activity?.sequences?.reduce((acc, seq) => {
                if (
                    seq.command === constants.COMMANDS.SCAN_FRAME ||
                    seq.command === constants.COMMANDS.COUNT_BEES_FRAME
                ) {
                    acc.push(seq);
                }
                return acc;
            }, []);
            if (scannedFrames?.length === 1) {
                const scannedFrame = scannedFrames[0];
                return {
                    text: `
                            Station ${scannedFrame?.params?.frame?.place?.station?.toUpperCase()}
                            Frame ${scannedFrame?.params?.frame?.id ?? ''}
                        `,
                    frame: scannedFrame?.params?.frame?.id,
                    rfid: scannedFrame?.params?.frame?.id,
                };
            }
            return {
                text: 'Scanning frames',
            };
        case constants.COMMANDS.FILL_FEEDER:
            const fedFrames = activity?.sequences?.reduce((acc, seq) => {
                if (seq.command === constants.COMMANDS.FILL_SYRUP) {
                    acc.push(seq);
                }
                return acc;
            }, []);
            if (fedFrames?.length === 1) {
                const fedFrame = fedFrames[0];
                return {
                    text: `
                            Station ${fedFrame?.params?.frame?.place?.station?.toUpperCase()}
                            Frame ${fedFrame?.params?.frame?.id ?? ''}
                        `,
                    frame: fedFrame?.params?.frame?.id,
                    rfid: fedFrame?.params?.frame?.id,
                };
            }
            return {
                text: 'Feeding frames',
            };
        case constants.COMMANDS.CONTRACT_HIVE:
            return { text: 'Contract hive' };
        case constants.COMMANDS.EXPAND_HIVE:
            return { text: 'Expand hive' };
        case constants.COMMANDS.SHIFT_HIVE:
            return { text: 'Shift hive' };
        case constants.COMMANDS.BALANCE_HIVES:
            return { text: 'Balance hive' };
        case constants.COMMANDS.THERMAL_SCAN_FULL:
            return { text: 'Thermal scan hives' };
        default:
            return {
                text: 'Unknown command',
            };
    }
};

export const parseTime = time => {
    const timeInHours = parseInt(time, 10) / 60;

    if (timeInHours < 1) {
        return `${Math.round(time)}min`;
    }

    const hours = Math.floor(timeInHours);

    const minutes = time - hours * 60;

    return `${hours}h ${Math.round(minutes)}min`;
};

export const getGroupedData = (data, dateProp) => groupBy(data, item => format(get(item, dateProp)));

export const getGroupedDataToShow = (groupedData, paginationCounter) =>
    Object.keys(groupedData).reduce((acc, key) => {
        const currentAccValue = acc;
        currentAccValue[key] = [];

        groupedData[key].forEach(item => {
            if (Object.values(currentAccValue).flat().length < paginationCounter * 5) {
                currentAccValue[key].push(item);
            }
        });

        if (currentAccValue[key].length) {
            acc = currentAccValue;
        }

        return acc;
    }, {});

export const EmptyPanePlaceholder = ({ text }) => (
    <div className="empty-pane-placeholder">
        <div className="empty-pane-placeholder-body">
            <FontAwesomeIcon icon={faFolderOpen} className="folder-icon" />
            <div className="empty-pane-text">{text}</div>
        </div>
    </div>
);

EmptyPanePlaceholder.propTypes = {
    text: PropTypes.string,
};

export const prepareFrames = ({ id, place, status, frameId }) => ({
    id,
    place,
    status,
    frameId,
});
