import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

const SelectComponent = ({ options, className, children, selection = true, disabled, ...rest }) => {
  const selectionProp = !selection ? {} : { selection: true };

  return (
    <Dropdown
      {...selectionProp}
      className={cx('beewise-select', { [className]: !!className, disabled: !!disabled })}
      options={options}
      {...rest}
    >
      {children}
    </Dropdown>
  );
};

SelectComponent.Divider = Dropdown.Divider;
SelectComponent.Header = Dropdown.Header;
SelectComponent.Item = Dropdown.Item;
SelectComponent.Menu = Dropdown.Menu;
SelectComponent.SearchInput = Dropdown.SearchInput;

SelectComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape()]).isRequired
    })
  ),
  className: PropTypes.string,
  children: PropTypes.node,
  selection: PropTypes.bool,
  disabled: PropTypes.bool
};

export default SelectComponent;
