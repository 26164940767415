import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { getFrameLeftPosition } from '../../utils';

const BeeFrame = ({ frame, commonWidth, isInsideHive, hiveStart, hiveEnd, beeCountFrameShift }) => {
    const beesPercent = (frame?.sideA?.beeCount?.beesPercent || 0) + (frame?.sideB?.beeCount?.beesPercent || 0);

    if (!beesPercent) {
        return;
    }

    return (
        <div
            key={`beecount-${frame.id}`}
            className={cx('bee-count-frame', {
                'reduced-opacity': isInsideHive,
            })}
            style={{
                left: `${getFrameLeftPosition({
                    positionX: frame.place.position.x,
                    hiveStart,
                    hiveEnd,
                    frameShift: beeCountFrameShift,
                })}%`,
                height: `${beesPercent / 2}%`,
                width: `${commonWidth}%`,
            }}
        />
    );
};

BeeFrame.propTypes = {
    frame: PropTypes.shape(),
    commonWidth: PropTypes.number,
    isInsideHive: PropTypes.bool,
    hiveStart: PropTypes.number,
    hiveEnd: PropTypes.number,
    beeCountFrameShift: PropTypes.number,
};

export default BeeFrame;
