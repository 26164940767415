import { sum, round, sumBy, chain, filter, sortBy } from 'lodash-es';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { UI_ENV } from 'config';
import { convertToImperialUnits, measureSystem, unitEnum } from './useMeasureSystem';

dayjs.extend(isSameOrAfter);

const MONTH_AMOUNT_DAYS = 30;
const HALF_YEAR_AMOUNT_DAYS = 180;

const DAY_AMOUNT_HOURS = 24;
const MONTH_AMOUNT_HOURS = DAY_AMOUNT_HOURS * MONTH_AMOUNT_DAYS;
const HALF_YEAR_AMOUNT_HOURS = DAY_AMOUNT_HOURS * HALF_YEAR_AMOUNT_DAYS;

export const REPORTS_ENUM = {
    HONEY_FLOW: 'honeyFlow',
    BROOD: 'brood',
    POLLEN_FLOW: 'pollenFlow',
    HONEY_WEIGHT: 'honeyWeight',
    TEMPERATURE: 'temperature',
    POPULATION: 'population',
};

export const ZOOM_LEVEL_ENUM = {
    DAY: '1 day',
    WEEK: '1 week',
    MONTH: '1 month',
    HALF_YEAR: '6 months',
    YEAR: '1 year',
    MAX: 'max',
};

export const reportKeys = {
    [REPORTS_ENUM.HONEY_FLOW]: {
        honeyCellsPerHive: 'honeyCellsPerHive',
    },
    [REPORTS_ENUM.BROOD]: {
        broodFramesPerHive: 'broodFramesPerHive',
    },
    [REPORTS_ENUM.POLLEN_FLOW]: {
        pollenCellsPerHive: 'pollenCellsPerHive',
    },
    [REPORTS_ENUM.HONEY_WEIGHT]: {
        broodFramesWeightPerHive: 'broodFramesWeightPerHive',
        nonBroodFramesWeightPerHive: 'nonBroodFramesWeightPerHive',
        summBroodAndNonBroodWeight: 'summBroodAndNonBroodWeight',
    },
    [REPORTS_ENUM.TEMPERATURE]: {
        outside: 'outside',
        insideTop: 'insideTop',
        insideBottom: 'insideBottom',
    },
    [REPORTS_ENUM.POPULATION]: {
        beeFramesPerHive: 'beeFramesPerHive',
    },
};

export const bhomeKey = 'beewise_reports_bhome';
export const hiveKey = 'beewise_reports_hive';
export const zoomLevelKey = 'beewise_reports_zoom_level';
export const reportKey = 'beewise_reports_plot_selected';
export const zoomLevels = Object.values(ZOOM_LEVEL_ENUM);

const subtractDate = (count, unit) => +dayjs().utc().subtract(count, unit);

const generateTicks = (amount, unit) =>
    new Array(amount)
        .fill(undefined)
        .map((_, idx) => +dayjs(dayjs().utc().subtract(idx, unit).startOf(unit).format()).utc());

const getZoomLevelDaysCount = zoomLevel => {
    switch (zoomLevel) {
        case ZOOM_LEVEL_ENUM.WEEK:
            return 8;
        case ZOOM_LEVEL_ENUM.MONTH:
            return 5;
        case ZOOM_LEVEL_ENUM.HALF_YEAR:
            return 25;
        case ZOOM_LEVEL_ENUM.YEAR:
            return 12;
        default:
            return 999999;
    }
};

const getZoomLevelOccurrence = zoomLevel => {
    switch (zoomLevel) {
        case ZOOM_LEVEL_ENUM.DAY:
            return 'hour';
        case ZOOM_LEVEL_ENUM.WEEK:
            return 'day';
        case ZOOM_LEVEL_ENUM.MONTH:
        case ZOOM_LEVEL_ENUM.HALF_YEAR:
            return 'week';
        case ZOOM_LEVEL_ENUM.YEAR:
            return 'month';
        default:
            return 'day';
    }
};

export const getStyleReportData = measureSystem => ({
    [REPORTS_ENUM.HONEY_FLOW]: [
        {
            fill: '#EB6E00',
            chartName: '# of honey cells',
            name: 'honey',
            show: true,
            key: reportKeys.honeyFlow.honeyCellsPerHive,
        },
    ],
    [REPORTS_ENUM.BROOD]: [
        {
            fill: '#DEA8A6',
            chartName: '# of brood frames',
            name: 'brood',
            show: true,
            key: reportKeys.brood.broodFramesPerHive,
        },
    ],
    [REPORTS_ENUM.POLLEN_FLOW]: [
        {
            fill: '#FFDE5E',
            chartName: '# of pollen cells',
            name: 'pollen',
            show: true,
            key: reportKeys.pollenFlow.pollenCellsPerHive,
        },
    ],
    [REPORTS_ENUM.HONEY_WEIGHT]: [
        {
            fill: '#FF9901',
            chartName: '# Non brood frames',
            name: 'all weight',
            show: true,
            unitValue: measureSystem.weight,
            key: 'summBroodAndNonBroodWeight',
        },
        {
            fill: '#EA9999',
            chartName: `# Brood frames, ${measureSystem.weight}`,
            name: 'brood frames weight',
            show: true,
            unitValue: measureSystem.weight,
            key: reportKeys.honeyWeight.broodFramesWeightPerHive,
        },
        {
            fill: '#00FF00',
            chartName: `# Non Brood frames, ${measureSystem.weight}`,
            name: 'non brood frames weight',
            show: false,
            unitValue: measureSystem.weight,
            key: reportKeys.honeyWeight.nonBroodFramesWeightPerHive,
        },
    ],
    [REPORTS_ENUM.TEMPERATURE]: [
        {
            fill: 'url(#colorBlue)',
            chartName: `Outside temperature, ${measureSystem.temp}`,
            name: 'outside',
            show: true,
            unitValue: measureSystem.temp,
            color: '#0075FF',
            withPattern: true,
            key: reportKeys.temperature.outside,
        },
        {
            fill: 'url(#colorRed)',
            chartName: 'Inside bottom temperature',
            name: 'inside bottom',
            show: true,
            unitValue: measureSystem.temp,
            color: '#D13913',
            withPattern: true,
            key: reportKeys.temperature.insideBottom,
            isSuperAdminAccess: true,
        },
        {
            fill: 'url(#colorGreen)',
            chartName: 'Inside top temperature',
            name: 'inside top',
            show: true,
            unitValue: measureSystem.temp,
            color: '#0F9960',
            withPattern: true,
            key: reportKeys.temperature.insideTop,
            isSuperAdminAccess: true,
        },
    ],
    [REPORTS_ENUM.POPULATION]: [
        {
            fill: '#FDBA1',
            chartName: '# of bee frames',
            name: 'population',
            show: true,
            key: reportKeys.population.beeFramesPerHive,
        },
    ],
    honey: [{ data: [] }],
});

// if we need to summ brood per hives, bhomes, etc
const generateGraphDataAndAggrPerPeriod = (keys, measureSystemName, units) => (value, key) => {
    const shouldConvert = measureSystemName === measureSystem.IMPERIAL.name;
    const keysMapped = keys.reduce((acc, keyName) => {
        const aggregatedValue = round(
            sumBy(value, o => {
                // take first date value of the range (day/week/month) instead of sum all data that appears in that range
                if (dayjs.utc(key).isSame(dayjs(o.date), 'date')) {
                    return o[keyName];
                }
                return 0;
            })
        );
        acc[keyName] = shouldConvert ? convertToImperialUnits(aggregatedValue, units) : aggregatedValue;

        return acc;
    }, {});
    return {
        name: key,
        date: +dayjs.utc(key),
        ...keysMapped,
    };
};

const generateGraphDataWithoutAggr = (keys, measureSystemName, units) => (value, key) => {
    const earliestRecord = sortBy(value, item => dayjs.utc(item.date))?.[0];

    const shouldConvert = measureSystemName === measureSystem.IMPERIAL.name;

    const keysMapped = keys.reduce((acc, keyName) => {
        const dataPerKey = earliestRecord ? earliestRecord[keyName] : 0;
        acc[keyName] = shouldConvert ? convertToImperialUnits(dataPerKey, units) : dataPerKey;
        return acc;
    }, {});

    return {
        name: key,
        date: +dayjs.utc(key),
        ...keysMapped,
    };
};

const generateGraphDataPerPeriod = ({
    data,
    zoomLevel,
    keys,
    measureSystemName,
    hasHourTimestamp,
    shouldAggrData,
    units,
}) => {
    const period = zoomLevel === ZOOM_LEVEL_ENUM.DAY ? 'hour' : 'date';
    if (zoomLevel === ZOOM_LEVEL_ENUM.DAY) {
        // show data only for last 24 hours
        const h24 = dayjs.utc().subtract(24, 'h');
        data = filter(data, occurrence => dayjs.utc(occurrence.date).isSameOrAfter(h24, 'hours'));
    }
    const appliedZoom = hasHourTimestamp && zoomLevel === 'max' ? ZOOM_LEVEL_ENUM.DAY : zoomLevel;

    const mappingFunc = shouldAggrData
        ? generateGraphDataAndAggrPerPeriod(keys, measureSystemName, units)
        : generateGraphDataWithoutAggr(keys, measureSystemName, units);
    return chain(data)
        .groupBy(occurrence => dayjs.utc(occurrence.date).startOf(getZoomLevelOccurrence(appliedZoom)).format())
        .map(mappingFunc)
        .sortBy(period)
        .value()
        .slice(-getZoomLevelDaysCount(zoomLevel));
};

const getGeneralFramesData = (graphData, bhome, hive, zoomLevel, keys) => {
    const dataToShow = bhome && bhome.id ? graphData.filter(row => row.bhome_id === bhome.id) : graphData;

    const aggregateFramesDataCount = (item, key) =>
        !hive && hive !== 0 ? sum(JSON.parse(item[key])) : JSON.parse(item[key])[hive];

    return dataToShow
        .map(row =>
            row?.aggr.map(item => {
                const keysMap = keys.reduce((acc, key) => {
                    acc[key] = item[key] ? aggregateFramesDataCount(item, key) : 0;
                    return acc;
                }, {});
                return { date: new Date(item.date), ...keysMap };
            })
        )
        .flat();
};

export const getPlotData = ({
    graphData = [],
    bhome,
    hive,
    zoomLevel,
    keys,
    measureSystemName,
    units,
    hasHourTimestamp,
    shouldAggrData = true,
}) => {
    const doNotShow1Day = zoomLevel === ZOOM_LEVEL_ENUM.DAY && !hasHourTimestamp;

    if (doNotShow1Day || (!shouldAggrData && (!bhome || !bhome.id))) {
        return [];
    }

    const data = shouldAggrData
        ? getGeneralFramesData(graphData, bhome, hive, zoomLevel, keys)
        : // eslint-disable-next-line prettier/prettier
          (graphData?.find(row => row.bhome_id === bhome.id)?.aggr ?? []);
    const params = {
        data,
        zoomLevel,
        keys,
        measureSystemName,
        hasHourTimestamp,
        shouldAggrData,
        units,
    };

    const result = generateGraphDataPerPeriod(params);

    if (zoomLevel === 'max') {
        if (!hasHourTimestamp) {
            if (result.length < MONTH_AMOUNT_DAYS) {
                return result;
            } else if (result.length < HALF_YEAR_AMOUNT_DAYS) {
                return generateGraphDataPerPeriod({
                    ...params,
                    zoomLevel: '6 months',
                });
            }
        } else {
            if (result.length < DAY_AMOUNT_HOURS) {
                return result;
            } else if (result.length < MONTH_AMOUNT_HOURS) {
                return generateGraphDataPerPeriod({
                    ...params,
                    zoomLevel: '1 month',
                });
            } else if (result.length < HALF_YEAR_AMOUNT_HOURS) {
                return generateGraphDataPerPeriod({
                    ...params,
                    zoomLevel: '6 months',
                });
            }
        }
        return generateGraphDataPerPeriod({ ...params, zoomLevel: '1 year' });
    }

    return result;
};

export const generateReportData = ({
    data: graphData,
    bhome,
    hive,
    zoomLevel,
    measureSystem,
    report,
    isSuperAdmin,
}) => {
    if (!graphData.length) {
        return [];
    }
    const keys = reportKeys[report] && Object.values(reportKeys[report]);
    switch (report) {
        case REPORTS_ENUM.HONEY_FLOW:
            return getPlotData({ graphData, bhome, hive, zoomLevel, keys });
        case REPORTS_ENUM.BROOD:
            return getPlotData({ graphData, bhome, hive, zoomLevel, keys });
        case REPORTS_ENUM.POLLEN_FLOW:
            return getPlotData({ graphData, bhome, hive, zoomLevel, keys });
        case REPORTS_ENUM.HONEY_WEIGHT:
            const honeyData = getPlotData({
                graphData,
                bhome,
                hive,
                zoomLevel,
                keys,
                measureSystemName: measureSystem.name,
                units: unitEnum.WEIGHT,
            });
            return honeyData.map(item => ({
                ...item,
                summBroodAndNonBroodWeight:
                    item[reportKeys.honeyWeight.broodFramesWeightPerHive] +
                    item[reportKeys.honeyWeight.nonBroodFramesWeightPerHive],
            }));
        case REPORTS_ENUM.TEMPERATURE:
            const filteredKeys = isSuperAdmin ? keys : keys.filter(item => item === reportKeys.temperature.outside);
            return getPlotData({
                graphData,
                bhome,
                zoomLevel,
                measureSystemName: measureSystem.name,
                keys: filteredKeys,
                hasHourTimestamp: true,
                units: unitEnum.TEMP,
                shouldAggrData: false,
            });
        case REPORTS_ENUM.POPULATION:
            return getPlotData({ graphData, bhome, hive, zoomLevel, keys });
        default:
            return [];
    }
};

export const getBhomeSelectOptions = bhomes =>
    bhomes
        ? bhomes.reduce(
              (acc, currentBhome) => {
                  const text = currentBhome.location
                      ? `${currentBhome.id} @ ${currentBhome.location}`
                      : `${currentBhome.id}`;
                  acc.push({
                      key: currentBhome.id,
                      value: currentBhome.id,
                      text,
                  });

                  return acc;
              },
              [
                  {
                      key: 'all',
                      value: 'all',
                      text: 'All Beehomes',
                  },
              ]
          )
        : [];

const weightReport =
    UI_ENV === 'dev' || UI_ENV === 'lab'
        ? [
              {
                  key: REPORTS_ENUM.HONEY_WEIGHT,
                  value: REPORTS_ENUM.HONEY_WEIGHT,
                  text: 'Honey weight',
              },
              {
                  key: REPORTS_ENUM.TEMPERATURE,
                  value: REPORTS_ENUM.TEMPERATURE,
                  text: 'Temperature',
              },
          ]
        : [];

export const getReportSelectOptions = () => [
    {
        key: REPORTS_ENUM.BROOD,
        value: REPORTS_ENUM.BROOD,
        text: 'Brood',
    },
    {
        key: REPORTS_ENUM.HONEY_FLOW,
        value: REPORTS_ENUM.HONEY_FLOW,
        text: 'Honey flow',
    },
    {
        key: REPORTS_ENUM.POLLEN_FLOW,
        value: REPORTS_ENUM.POLLEN_FLOW,
        text: 'Pollen flow',
    },
    {
        key: REPORTS_ENUM.POPULATION,
        value: REPORTS_ENUM.POPULATION,
        text: 'Population',
    },
    ...weightReport,
];

export const tickFormatter = zoomLevel => tickItem =>
    zoomLevel !== ZOOM_LEVEL_ENUM.DAY ? dayjs(tickItem).utc().format('D MMM') : dayjs(tickItem).utc().format('h:mm A');

const generateMaxTicks = options => {
    const optLength = options.length;

    if (optLength && optLength > 1) {
        const difference = Math.abs(dayjs(options[0].date).diff(dayjs(options[1].date), 'day'));

        // generate ticks if case options difference are days / weeks / months
        if (difference === 1) {
            return generateTicks(options.length, 'day');
        } else if (difference === 7) {
            return generateTicks(options.length, 'week');
        }

        return generateTicks(dayjs().diff(dayjs(options[0].date), 'month'), 'month');
    }
};

export const getPlotOptions = (zoomLevel, options) => {
    switch (zoomLevel) {
        case ZOOM_LEVEL_ENUM.DAY:
            return {
                domain: ['dataMin', 'dataMax'],
                ticks: generateTicks(24, 'hour'),
            };
        case ZOOM_LEVEL_ENUM.WEEK:
            return {
                domain: ['dataMin', 'dataMax'],
                ticks: generateTicks(7, 'day'),
            };
        case ZOOM_LEVEL_ENUM.MONTH:
            return {
                domain: [() => dayjs.utc(subtractDate(4, 'week')).startOf('week'), 'dataMax'],
                ticks: generateTicks(4, 'week'),
            };
        case ZOOM_LEVEL_ENUM.HALF_YEAR:
            return {
                domain: [() => dayjs.utc(subtractDate(24, 'week')).startOf('week'), 'dataMax'],
                ticks: generateTicks(24, 'week'),
            };
        case ZOOM_LEVEL_ENUM.YEAR:
            return {
                domain: [() => dayjs.utc(subtractDate(1, 'year')).startOf('month'), 'dataMax'],
                ticks: generateTicks(12, 'month'),
            };
        case ZOOM_LEVEL_ENUM.MAX:
            return {
                domain: ['dataMin', 'dataMax'],
                ticks: generateMaxTicks(options),
            };
        default:
            return {
                domain: ['dataMin', 'dataMax'],
                ticks: generateTicks(options.length, 'day'),
            };
    }
};
