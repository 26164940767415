import { useEffect, useRef, useState } from 'react';
import { ACTION_ITEM_HEIGHT, HEADER_HEIGHT } from './utils';

const useVirtualizedList = ({ data }) => {
    const listRef = useRef(null);
    const [visibleStartIndex, setVisibleStartIndex] = useState(0);

    const rowHeights = new Array(data.length)
        .fill(true)
        .map((_, index) => (data[index]?.header ? HEADER_HEIGHT : ACTION_ITEM_HEIGHT));

    const getItemSize = index => rowHeights[index];

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [data.length]);

    return {
        listRef,
        visibleStartIndex,
        setVisibleStartIndex,
        getItemSize,
    };
};

export default useVirtualizedList;
