import constants from 'appConstants';
import { groupBy } from 'lodash-es';
import { getHiveIndexForFrame } from '../../../../utils';

const setEndOfHive = ({ frame, hive, isLastFrameInStation, frameWidth, end }) => {
    // add frames to prev hive
    hive.frames.push(frame);
    // add end of prev hive if it's last frame in station or frame is static partition
    if (frame.type !== constants.FRAME_TYPES.STATIC_PARTITION && !isLastFrameInStation) {
        return;
    }
    const lastFrameX = Math.ceil(frame.place.position.x + frameWidth / 2);
    const stationEnd = end?.x ?? lastFrameX;
    hive.to = isLastFrameInStation ? stationEnd : lastFrameX;
};

export const groupFramesByHives = ({ station, groupedFramesByStation, stations }) => {
    const { end, start } = stations[station] || {};
    const frames = groupedFramesByStation?.[station]?.sort(
        (frameA, frameB) => frameA.place.position.x - frameB.place.position.x
    );
    return (
        frames?.reduce((acc, item, index, fullArr) => {
            const prevHive = acc[acc.length - 1];
            const prevHiveEndX = prevHive?.to;
            const isPrevHiveFull = !!prevHiveEndX;
            const frameWidth = constants.DEFAULT_FRAME_WIDTH[item.type.toUpperCase()];
            const isNewHive = !acc.length || isPrevHiveFull;
            if (isNewHive) {
                // handle cases if station end and start are not defined
                const stationStart = start?.x ?? item.place.position.x - frameWidth / 2;
                acc.push({
                    from: !prevHive ? stationStart : prevHiveEndX,
                    to: null,
                    frames: [item],
                    station,
                    index: acc.length,
                    id: `${station}-${item.place.position.x}`,
                    name: `${station.toUpperCase()}${acc.length + 1}`,
                });
            } else {
                const isLastFrameInStation = index === fullArr.length - 1;
                setEndOfHive({ frame: item, hive: prevHive, isLastFrameInStation, frameWidth, end });
            }
            return acc;
        }, []) ?? []
    );
};

export const groupFramesByStationAndHives = (frames, stations) => {
    const groupedFramesByStation = groupBy(frames, 'place.station');
    const stationAHives = groupFramesByHives({ station: constants.STATIONS.A, groupedFramesByStation, stations });
    const stationBHives = groupFramesByHives({ station: constants.STATIONS.B, groupedFramesByStation, stations });
    return {
        [constants.STATIONS.A]: stationAHives,
        [constants.STATIONS.B]: stationBHives,
    };
};

const FRAME_COMMANDS = [constants.COMMANDS.SCAN, constants.COMMANDS.COUNT_BEES, constants.COMMANDS.FILL_FEEDER];

export const getMostFrequentMatch = arr => {
    const frequencyMap = new Map();
    arr.forEach(num => frequencyMap.set(num, (frequencyMap.get(num) || 0) + 1));
    return [...frequencyMap.entries()].reduce((a, b) => (b[1] > a[1] ? b : a))[0];
};

const getPayloadForCommand = ({ command, payload, hives }) => {
    const isFrameCommand = FRAME_COMMANDS.includes(command);
    if (isFrameCommand) {
        const hiveIndexes = payload.frames?.map(frame => getHiveIndexForFrame(hives, frame));
        const hiveIndex = hiveIndexes && getMostFrequentMatch(hiveIndexes);

        return { hive: hiveIndex !== -1 && hives[hiveIndex]?.name };
    }
    return payload;
};

const getPayloadForGroupedMessages = ({ messages, hives }) => {
    const [firstMessage] = messages;
    const { command } = firstMessage;

    if (FRAME_COMMANDS.includes(command)) {
        const messageHives = messages
            .map(({ data: { hive } }, index) => ({
                hive,
                id: messages[index].id,
            }))
            .filter(({ hive }) => Boolean(hive))
            .sort((a, b) => a.hive.localeCompare(b.hive));

        return {
            hives: messageHives.map(({ hive }) => hive),
            messages: messageHives.map(({ id }) => id),
            isAllHives: messageHives.length === hives.length,
        };
    }

    return firstMessage.data;
};

export const mapHivesToMessages = (filteredMessages, hivesByStation) => {
    const hives = [...hivesByStation[constants.STATIONS.A], ...hivesByStation[constants.STATIONS.B]];
    const messagesWithHives = filteredMessages.map(({ payload, id, command, sentAt, user, status }) => ({
        id,
        command,
        sentAt: new Date(sentAt),
        user,
        data: getPayloadForCommand({ command, payload, hives }),
        status,
    }));
    const groupedMessages = groupBy(
        messagesWithHives,
        ({ user, command, sentAt }) => `${user}-${command}-${sentAt.toLocaleString()}`
    );
    const groupedArray = Object.values(groupedMessages).map(messages => ({
        command: messages[0].command,
        sentAt: messages[0].sentAt,
        user: messages[0].user,
        data: getPayloadForGroupedMessages({ messages, hives }),
        status: messages[0].status,
    }));

    return groupedArray.sort((a, b) => b.sentAt - a.sentAt);
};
