import { eventChannel } from 'redux-saga';
import { call, cancel, cancelled, fork, put, select, take } from 'redux-saga/effects';
import io from 'socket.io-client';
import { auth } from '@beewise/react-utils';
import * as config from 'config';
import constants from 'appConstants';
import { FETCH_SIGNIN, SIGN_OUT, SOCKET_CONNECTION_FAILED } from 'components/views/SignIn/actionTypes';
import { SET_CURRENT_BHOME } from 'components/views/BeeHome/actionTypes';
import { UPDATE_ADMIN_COMPANY, UPDATE_ADMIN_COMPANY_BY_BHOME_ID } from 'components/views/Admin/actionTypes';
import { SET_HALLWAY_VIDEO_URL } from 'components/reusables/RightPanel/actionTypes';
import {
    ON_CALCULATE_LAYOUT,
    ON_IN_PROGRESS_ACTIVITIES_CANCEL,
    ON_MESSAGE_UPDATE,
    ON_NEW_MESSAGE_RECEIVE,
    ON_RECEIVE_NEW_ALERT,
    ON_UPDATE_BHOME_STATUS,
    ON_UPDATE_FRAME_DATA,
    ON_UPDATE_SYRUP_LEVEL,
    ON_UPDATE_HONEY_LEVEL,
    ON_UPDATE_FRAME,
    ON_REMOVE_SWARM_DATA,
    ON_UPDATE_CONFIG,
    ON_REPORT_HOURLY_SOLAR_DATA,
    SOCKET_CONNECTED,
    ON_UPDATE_GPS_LOCATION,
    ON_GET_REVERTED_ENTRANCES,
    ON_SET_LAYOUT,
    ON_RECEIVE_NEW_ROBOT_STATUS,
} from '../actionTypes';

let connection = null;

const connect = async (currentBhomeId = null) => {
    const user = auth.getUser();

    if (user && currentBhomeId) {
        const socket = io(`${config.API_URL}/company-${user.companyId}`, {
            query: `bhomeId=${currentBhomeId}&token=${auth.getSocketJwtToken()}`,
            // apply forceNew to avoid old bhomeId socket to be returned from cache (io Manager)
            forceNew: true,
            transports: ['websocket'],
        });

        return new Promise(resolve => {
            socket.on('connect', () => {
                resolve(socket);
            });
            socket.on('connect_error', error => {
                if (
                    error?.message?.toLowerCase?.()?.includes?.('invalid signature') ||
                    error?.message?.toLowerCase?.()?.includes?.('malformed')
                ) {
                    resolve({ error: true });
                }
            });
        });
    }

    return null;
};

const createSocketChannel = socket =>
    eventChannel(emit => {
        const emmiter = data => {
            // eslint-disable-next-line no-console
            console.log('Message WS: ', data);
            emit(data);
        };
        socket.on('data', emmiter);
        return () => {
            socket.off('data', emmiter);
        };
    });

function* socketConnect() {
    let socket;
    let socketChannel;

    try {
        const currentBhomeId = yield select(state => state.beehome.currentBhome?.id);

        if (!currentBhomeId) {
            return;
        }

        socket = yield call(connect, currentBhomeId);

        if (!socket) {
            return;
        }

        if (socket.error) {
            yield put({ type: SOCKET_CONNECTION_FAILED });
            return;
        }

        yield put({ type: SOCKET_CONNECTED });

        socketChannel = yield call(createSocketChannel, socket);

        while (true) {
            let wsAction = yield take(socketChannel);
            // TODO action creator, action names to shared after finishing
            wsAction = JSON.parse(wsAction);
            const { event, payload } = wsAction;

            if (event === constants.EVENT_NAMES.UPDATE_MESSAGE_STATUS) {
                yield put({ type: ON_MESSAGE_UPDATE, payload });
            } else if (event === constants.EVENT_NAMES.CALCULATE_LAYOUT) {
                yield put({
                    type: ON_CALCULATE_LAYOUT,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_MESSAGE) {
                yield put({ type: ON_NEW_MESSAGE_RECEIVE, payload });
            } else if (event === constants.EVENT_NAMES.CANCEL_IN_PROGRESS_ACTIONS) {
                yield put({
                    type: ON_IN_PROGRESS_ACTIVITIES_CANCEL,
                    payload,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_BHOME_STATUS) {
                yield put({
                    type: ON_UPDATE_BHOME_STATUS,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (
                event === constants.EVENT_NAMES.UPDATE_SYRUP_LEVEL ||
                event === constants.EVENT_NAMES.UPDATE_SYRUP_LEVEL_SENSORS
            ) {
                yield put({
                    type: ON_UPDATE_SYRUP_LEVEL,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_HONEY_LEVEL) {
                yield put({
                    type: ON_UPDATE_HONEY_LEVEL,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_FRAME_DATA) {
                yield put({
                    type: ON_UPDATE_FRAME_DATA,
                    payload: payload.data,
                    id: wsAction.payload.id,
                });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_ALERT) {
                yield put({
                    type: ON_RECEIVE_NEW_ALERT,
                    payload: payload.data.alert,
                    id: wsAction.payload.id,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_FRAME) {
                yield put({
                    type: ON_UPDATE_FRAME,
                    payload: payload.updatedFrame,
                    bhomeId: payload.bhomeId,
                    slotIndex: payload.slotIndex,
                });
            } else if (event === constants.EVENT_NAMES.REMOVE_SWARM_DATA) {
                yield put({
                    type: ON_REMOVE_SWARM_DATA,
                    payload: payload.frames,
                    bhomeId: payload.bhomeId,
                });
            } else if (event === constants.EVENT_NAMES.REPORT_HOURLY_SOLAR_DATA) {
                yield put({ type: ON_REPORT_HOURLY_SOLAR_DATA, payload });
            } else if (event === constants.EVENT_NAMES.UPDATE_CONFIG) {
                yield put({
                    type: ON_UPDATE_CONFIG,
                    settings: payload.settings,
                    bhomeId: payload.bhomeId,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_GPS_LOCATION) {
                yield put({
                    type: ON_UPDATE_GPS_LOCATION,
                    gps: wsAction.gps,
                    yard_id: wsAction.yard_id,
                    yard_name: wsAction.yard_name,
                    bhomeId: wsAction.bhomeId,
                });
            } else if (event === constants.EVENT_NAMES.GET_REVERTED_ENTRANCES) {
                yield put({
                    type: ON_GET_REVERTED_ENTRANCES,
                    revertEntrances: payload.revertEntrances,
                    bhomeId: payload.bhomeId,
                });
            } else if (event === constants.EVENT_NAMES.SET_LAYOUT) {
                yield put({
                    type: ON_SET_LAYOUT,
                    data: { ...payload.data },
                });
            } else if (event === constants.EVENT_NAMES.TOGGLE_HALLWAY_VIDEO) {
                yield put({ type: SET_HALLWAY_VIDEO_URL, payload });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_ROBOT_STATUS) {
                yield put({ type: ON_RECEIVE_NEW_ROBOT_STATUS, payload });
            }
        }
    } finally {
        if (yield cancelled()) {
            connection = null;
            if (socketChannel) {
                socketChannel.close();
            }
            if (socket) {
                socket.close();
            }
        }
    }
}

function* disconnect() {
    yield cancel(connection);
}

function* selectCurrentBhome({ avoidSocketCreation = false }) {
    yield cancel(connection);
    // added for SUPER_ADMIN purposes (to avoid socket declaration duplication)
    if (!avoidSocketCreation) {
        connection = yield fork(socketConnect);
    }
}

// eslint-disable-next-line func-names
const customTakeEvery = (pattern, saga, ...args) =>
    // eslint-disable-next-line func-names
    fork(function* () {
        while (true) {
            const action = yield take(pattern);
            connection = yield fork(saga, ...args.concat(action));
        }
    });

export default function* root() {
    connection = yield fork(socketConnect);
    yield customTakeEvery(FETCH_SIGNIN.success, socketConnect);
    yield customTakeEvery(SET_CURRENT_BHOME, selectCurrentBhome);
    yield customTakeEvery(UPDATE_ADMIN_COMPANY.success, selectCurrentBhome);
    yield customTakeEvery(UPDATE_ADMIN_COMPANY_BY_BHOME_ID.success, selectCurrentBhome);
    yield customTakeEvery(SIGN_OUT, disconnect);
}
