import React, { useCallback, useState, memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { loading } from '@beewise/react-utils';
import Checkbox from '@beewise/checkbox';
import Button from '@beewise/button';
import Modal from '@beewise/modal';
import { useFeatureFlag } from 'components/reusables/FeatureFlags';
import { deleteUser } from '../../actions';
import { GET_COMPANY } from '../../actionTypes';
import getCurrentBhomeIds from './selectors';

const PermissionsBlock = ({ members }) => {
    const [removeData, setRemoveData] = useState({});
    const [isRemoveModalOpened, setModalOpened] = useState(false);
    const bhomeIds = useSelector(getCurrentBhomeIds);
    const dispatch = useDispatch();
    const isActionEnabled = useFeatureFlag('deleteUser');

    const closeModal = useCallback(() => {
        setRemoveData({});
        setModalOpened(false);
    }, []);

    return (
        <div className="settings-item-block">
            {members.map(({ id, username, email }) => (
                <div key={id} className="member-item">
                    <div>
                        <span className={cx({ 'member-item-name': !username })}>{username || email}</span>
                        {!username && (
                            <span className="member-item-sub">(the user has not yet accepted the invitation)</span>
                        )}
                    </div>
                    <div className="permissions">
                        <Checkbox disabled label="Can inspect" name={`inspect-${id}`} checked readOnly />
                        <Checkbox disabled label="Can operate" name={`operate-${id}`} checked readOnly />
                        {isActionEnabled && (
                            <Button
                                iconType="trash"
                                className="remove-user"
                                onClick={() => {
                                    setRemoveData({ id, username, email });
                                    setModalOpened(true);
                                }}
                            />
                        )}
                    </div>
                </div>
            ))}
            <Modal
                header={`Remove ${removeData.username || removeData.email}`}
                isOpen={isRemoveModalOpened}
                onClose={closeModal}
                className="remove-user-modal"
            >
                They will not be able to access Beehome
                {bhomeIds.length > 1 ? 's' : ''} {bhomeIds.sort().join(', ')} anymore.
                <div className="remove-modal-actions">
                    <Button className="btn-secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        className="btn-primary"
                        onClick={() => {
                            dispatch(deleteUser(removeData.id, closeModal));
                        }}
                    >
                        Remove
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

PermissionsBlock.propTypes = {
    members: PropTypes.arrayOf(PropTypes.shape()),
};

export default loading(GET_COMPANY.default)(memo(PermissionsBlock));
