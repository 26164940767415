import React, { useCallback, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { shallowEqual } from '@beewise/react-utils';
import PropTypes from 'prop-types';
import { Tab, TabPane } from 'components/reusables/Tab';
import constants from 'appConstants';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import isSignInOrUpView from 'utils/isSignInOrUpView';
import { getIsLoggedInAsSuperAdmin } from 'components/views/Admin/selectors';
import { getInspectedFrame, getInspectedFrameData } from 'components/views/BeeHome/selectors';
import { setFrameToInspect, fetchInspectedFrameData } from 'components/views/BeeHome/actions';
import { getActionMode } from 'components/reusables/RightPanel/selectors';
import Log from './components/Log';
import Activities from './components/Activities';
import HiveBlock from './components/HiveBlock';
import RightPanelHeader from './components/RightPanelHeader';
import UnsavedActivities from './components/UnsavedActivities';

import './RightPanel.scss';

const InspectedFrameView = memo(({ panes }) => <Tab panes={panes} />);

InspectedFrameView.propTypes = {
    panes: PropTypes.arrayOf(
        PropTypes.shape({
            menuItem: PropTypes.string,
            render: PropTypes.func,
        })
    ),
};

const DefaultView = ({ inspectedFrame, inspectedData }) => (
    <>
        {inspectedFrame?.frameId && <HiveBlock data={inspectedData} frame={inspectedFrame} />}
        <Log frame={inspectedFrame} />
        <Activities frame={inspectedFrame} />
    </>
);

DefaultView.propTypes = {
    inspectedFrame: PropTypes.shape({ frameId: PropTypes.string, id: PropTypes.number }),
    inspectedData: PropTypes.shape({}),
};

const RightPanel = ({ isMapShown }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isLoggedInAsSuperAdmin = useSelector(getIsLoggedInAsSuperAdmin);
    const inspectedFrame = useSelector(getInspectedFrame, shallowEqual);
    const inspectedData = useSelector(getInspectedFrameData, shallowEqual);
    const actionMode = useSelector(getActionMode, shallowEqual);

    const isFullWidthPage = ['/settings', '/reports'].includes(location.pathname);

    useEffect(() => {
        if (inspectedFrame?.type === constants.FRAME_TYPES.COMB_FRAME) {
            dispatch(fetchInspectedFrameData({ frameId: inspectedFrame.frameId }));
        }
    }, [dispatch, inspectedFrame?.frameId, inspectedFrame?.type]);

    const handleTitleClick = useCallback(() => {
        dispatch(setFrameToInspect({}));
    }, [dispatch]);

    const handleFrameClick = useCallback(
        frame => {
            dispatch(setFrameToInspect(frame));
        },
        [dispatch]
    );

    const panes = [
        {
            menuItem: 'More information',
            render: () => (
                <TabPane>
                    <Log frame={inspectedFrame} />
                    <Activities frame={inspectedFrame} />
                </TabPane>
            ),
        },
    ];

    if (inspectedFrame?.type === constants.FRAME_TYPES.COMB_FRAME) {
        panes.unshift({
            menuItem: 'Scans',
            render: () => (
                <TabPane>
                    {inspectedFrame?.frameId && (
                        <HiveBlock onFrameClick={handleFrameClick} data={inspectedData} frame={inspectedFrame} />
                    )}
                </TabPane>
            ),
        });
    }

    let content;
    if (inspectedFrame?.frameId) {
        content = <InspectedFrameView panes={panes} />;
    } else {
        content = (
            <DefaultView
                {...{
                    isMapShown,
                    inspectedFrame,
                    inspectedData,
                }}
            />
        );
    }

    if (
        isSignInOrUpView(location) ||
        (isLoggedInAsSuperAdmin && location.pathname.includes('admin')) ||
        isFullWidthPage
    ) {
        return null;
    }

    return (
        <aside className={cx('aside', { 'is-map-shown': isMapShown })}>
            {actionMode && <UnsavedActivities />}
            <RightPanelHeader frame={inspectedFrame} onTitleClick={handleTitleClick} isMapShown={isMapShown} />
            <Scrollbars className="aside-content">{content}</Scrollbars>
        </aside>
    );
};

RightPanel.propTypes = {
    isMapShown: PropTypes.bool,
};

export default RightPanel;
