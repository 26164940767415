import { createSelector } from 'reselect';
import constants from 'appConstants';

export const getCurrentBhome = state => state.beehome.currentBhome;

export const getCurrentBhomeId = state => state.beehome.currentBhome?.id;

export const getCurrentBhomeFrames = state => state.beehome?.frames ?? [];

export const getSelectedHives = state => state.beehome.selectedHives;

export const getIsHiveSelected = createSelector(
    getSelectedHives,
    (state, hiveId) => hiveId,
    (selectedHives, hiveId) => selectedHives.includes(hiveId)
);

export const getInspectedFrame = state => state.beehome.frameToInspect;

export const getInspectedHive = state => state.beehome.hiveToInspect;

export const getIsHiveInspected = createSelector(
    getInspectedHive,
    (state, hiveId) => hiveId,
    (inspectedHive, hiveId) => inspectedHive === hiveId
);

export const getInspectedFrameData = state => state.beehome.inspectedFrameData;

export const getCurrentBhomeFromList = createSelector(
    state => state.beehome.bhomes,
    (state, id) => id,
    (bhomes, id) => bhomes.find(item => item.id === id) || {}
);

export const getLastFeedDate = createSelector(getCurrentBhomeFrames, frames => {
    const lastFeedDate = frames
        .filter(frame => frame.type === constants.FRAME_TYPES.FEEDER)
        .map(frame => new Date(frame.lastUpdated));

    return lastFeedDate.length ? Math.max(...lastFeedDate) : null;
});

export const getCurrentBhomeFromListById = id => state => getCurrentBhomeFromList(state, Number(id));

export const getThermalScanData = state => state.bhome.thermalScanData;

export const getHiveThermalScanData = createSelector(
    [getThermalScanData, (_, station) => station, (_, __, from) => from, (_, __, ___, to) => to],
    (thermalScanData, station, from, to) => {
        const filteredData = thermalScanData.filter(
            dataPoint => station === dataPoint.station && dataPoint.x >= from && dataPoint.x <= to
        );

        // taken from Yonatan's math requirements
        const a = -0.19761159;
        const b = -0.51897655;

        const dataWithRegulatedTemp = filteredData.map(dataPoint => {
            const { irReading, outerTemp, innerTemp } = dataPoint;
            const regulatedTemp = parseFloat(irReading) + a * outerTemp + b * innerTemp;
            return {
                ...dataPoint,
                regulatedTemp,
            };
        });

        const meanRegulatedTemp =
            dataWithRegulatedTemp.reduce((acc, dataPoint) => acc + dataPoint.regulatedTemp, 0) /
            dataWithRegulatedTemp.length;

        const normalizedData = dataWithRegulatedTemp.map(dataPoint => ({
            ...dataPoint,
            normalizedTemp: dataPoint.regulatedTemp - meanRegulatedTemp,
        }));

        return normalizedData;
    }
);
