import { createAsyncAction } from '@beewise/utils';

export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const MODAL_CLOSED = 'MODAL_CLOSED';
export const ON_MESSAGE_UPDATE = 'ON_MESSAGE_UPDATE';
export const ON_RECEIVE_NEW_ROBOT_STATUS = 'ON_RECEIVE_NEW_ROBOT_STATUS';
export const ON_CALCULATE_LAYOUT = 'ON_CALCULATE_LAYOUT';
export const ON_NEW_MESSAGE_RECEIVE = 'ON_NEW_MESSAGE_RECEIVE';
export const ON_IN_PROGRESS_ACTIVITIES_CANCEL = 'ON_IN_PROGRESS_ACTIVITIES_CANCEL';
export const ON_UPDATE_BHOME_STATUS = 'ON_UPDATE_BHOME_STATUS';
export const ON_UPDATE_CONFIG = 'ON_UPDATE_CONFIG';
export const ON_UPDATE_SYRUP_LEVEL = 'ON_UPDATE_SYRUP_LEVEL';
export const ON_UPDATE_GPS_LOCATION = 'ON_UPDATE_GPS_LOCATION';
export const ON_GET_REVERTED_ENTRANCES = 'ON_GET_REVERTED_ENTRANCES';
export const ON_UPDATE_HONEY_LEVEL = 'ON_UPDATE_HONEY_LEVEL';
export const ON_REMOVE_SWARM_DATA = 'ON_REMOVE_SWARM_DATA';
export const ON_UPDATE_FRAME_DATA = 'ON_UPDATE_FRAME_DATA';
export const ON_RECEIVE_NEW_ALERT = 'ON_RECEIVE_NEW_ALERT';
export const ON_UPDATE_FRAME = 'ON_UPDATE_FRAME';
export const ON_REPORT_HOURLY_SOLAR_DATA = 'ON_REPORT_HOURLY_SOLAR_DATA';
export const ON_SET_LAYOUT = 'ON_SET_LAYOUT';

export const CHANGE_ALERT_PANE_STATE = 'CHANGE_ALERT_PANE_STATE';
export const SHOW_ACTIVITY_BAR = 'SHOW_ACTIVITY_BAR';
export const CHANGE_CURRENT_USER = 'CHANGE_CURRENT_USER';
export const SET_ENTRANCES_STATE = 'SET_ENTRANCES_STATE';
export const SET_FULL_SCREEN_IMAGE = 'SET_FULL_SCREEN_IMAGE';
export const TOGGLE_MAP = 'TOGGLE_MAP';
export const TOGGLE_RIGHT_PANEL = 'TOGGLE_RIGHT_PANEL';
export const TOGGLE_INITIAL_RENDER = 'TOGGLE_INITIAL_RENDER';
export const FETCH_APP_SETTINGS = createAsyncAction('FETCH_APP_SETTINGS');
