import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import constants from 'appConstants';
import { noop } from 'lodash-es';
import { getCurrentBhomeId } from 'components/views/BeeHome/selectors';
import { fetchFrames, fetchBhomeNotes } from 'components/views/BeeHome/actions';
import { updateHiveAssessment } from '../../actions';
import { getHiveId } from '../../selectors/helpers/general.helpers';
import StationAssessment from './StationAssessment';
import AssessmentModal from './AssessmentModal';

const StationHive = ({
    hive,
    stationEnd,
    stationStart,
    index,
    settings,
    isModalMode = false,
    onHiveSelectModalMode,
    selectedHiveModalMode,
}) => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState();
    const [additionalModalProps, setAdditionalModalProps] = useState({});
    const currentBhomeId = useSelector(getCurrentBhomeId);
    const id = getHiveId(hive);

    const adjustedXFrom =
        hive.from -
        stationStart +
        (settings?.physical_measurements?.partition_width ?? constants.DEFAULT_FRAME_WIDTH.PARTITION);
    const adjustedXTo =
        hive.to -
        stationStart -
        (settings?.physical_measurements?.static_partition_width ?? constants.DEFAULT_FRAME_WIDTH.STATIC_PARTITION);
    const left = `${(adjustedXFrom / (stationEnd - stationStart)) * 100}%`;
    const width = `${((adjustedXTo - adjustedXFrom) / (stationEnd - stationStart)) * 100}%`;

    const handleRadioChange = useCallback(() => {
        if (!isModalMode) {
            return;
        }

        onHiveSelectModalMode(hive);
    }, [hive, isModalMode, onHiveSelectModalMode]);

    const getTooltipContent = () => {
        const isHiveDead = hive?.assessment === constants.HIVE_ASSESSMENT.DEAD;

        return (
            <StationAssessment
                hive={hive}
                index={index}
                menuOptions={[
                    {
                        label: 'Mark is dead',
                        onClick: () => {
                            setAdditionalModalProps({
                                title: 'Mark as dead',
                                contentText: 'Are you sure you want to mark this hive as dead ?',
                                submitText: 'Mark dead',
                                onSubmit: () =>
                                    dispatch(
                                        updateHiveAssessment({
                                            bhomeId: currentBhomeId,
                                            hiveId: hive.hiveId,
                                            body: { assessment: constants.HIVE_ASSESSMENT.DEAD },
                                            resolver: () => {
                                                dispatch(fetchBhomeNotes(currentBhomeId));
                                                dispatch(fetchFrames(currentBhomeId, () => setIsModalOpen(false)));
                                            },
                                        })
                                    ),
                            });
                            setIsModalOpen(true);
                        },
                        isDisabled: isHiveDead,
                        ...(isHiveDead
                            ? {
                                  tooltip: 'Hive was previously marked as dead',
                              }
                            : {}),
                    },
                    {
                        label: 'Repopulated',
                        onClick: () => {
                            setAdditionalModalProps({
                                title: 'Repopulated Hive',
                                contentText: 'Are you sure you want to mark this hive as repopulated ?',
                                submitText: "I'm sure",
                                onSubmit: () =>
                                    dispatch(
                                        updateHiveAssessment({
                                            bhomeId: currentBhomeId,
                                            hiveId: hive.hiveId,
                                            body: { assessment: constants.HIVE_ASSESSMENT.REPOPULATED },
                                            resolver: () => {
                                                dispatch(fetchBhomeNotes(currentBhomeId));
                                                dispatch(fetchFrames(currentBhomeId, () => setIsModalOpen(false)));
                                            },
                                        })
                                    ),
                            });
                            setIsModalOpen(true);
                        },
                    },
                ]}
            />
        );
    };

    if (isModalMode) {
        return (
            <>
                <span role="button" className="btn-primary btn-gradient station-hive" style={{ left, width }} />
                <div className="station-hive-radio" style={{ left }}>
                    <input
                        id={`hive-radio-${id}`}
                        type="radio"
                        role="button"
                        onKeyDown={noop}
                        onChange={handleRadioChange}
                        checked={selectedHiveModalMode?.from === hive.from && selectedHiveModalMode?.to === hive.to}
                    />
                    <label htmlFor={`hive-radio-${id}`}>{`Hive ${hive.index + 1}`}</label>
                </div>
            </>
        );
    } else {
        return (
            <>
                <Tooltip
                    content={getTooltipContent()}
                    position="top"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    isHoverable
                    hideOnScroll
                    className="station-assessment-tooltip-content"
                >
                    <span
                        tabIndex={index}
                        role="button"
                        className="station-hive btn-primary btn-gradient"
                        style={{ left, width }}
                    />
                </Tooltip>
                <AssessmentModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} {...additionalModalProps} />
            </>
        );
    }
};

StationHive.propTypes = {
    hive: PropTypes.shape({
        from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isMarked: PropTypes.bool,
        index: PropTypes.number,
        assessment: PropTypes.string,
        hiveId: PropTypes.string,
    }).isRequired,
    isModalMode: PropTypes.bool,
    stationEnd: PropTypes.number.isRequired,
    stationStart: PropTypes.number.isRequired,
    settings: PropTypes.shape(),
    index: PropTypes.number,
    onHiveSelectModalMode: PropTypes.func,
    selectedHiveModalMode: PropTypes.shape(),
};

export default StationHive;
