import { isEmpty } from 'lodash-es';
import { auth, PERMISSIONS_ACTION_TYPES } from '@beewise/react-utils';
import { FETCH_SIGNIN, SIGN_OUT, SOCKET_CONNECTION_FAILED } from 'components/views/SignIn/actionTypes';
import { UPDATE_ADMIN_COMPANY, UPDATE_ADMIN_COMPANY_BY_BHOME_ID } from 'components/views/Admin/actionTypes';
import { CHANGE_ALERT_PANE_STATE, CHANGE_CURRENT_USER, FETCH_APP_SETTINGS } from 'actionTypes';
import { identifyFullStoryUser } from 'utils/fullStory';

auth.init({
    USER: 'beewise_user',
    JWT_TOKEN: 'beewise_jwt_token',
    SOCKET_JWT_TOKEN: 'beewise_socket_jwt_token',
});

const parsedUser = auth.getUser();

const initialState = {
    user: parsedUser || {},
    isLoggedIn: !isEmpty(parsedUser),
    isAlertPaneShown: false,
    permission: null,
    appSettings: {},
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SIGNIN.success:
        case UPDATE_ADMIN_COMPANY.success:
        case UPDATE_ADMIN_COMPANY_BY_BHOME_ID.success:
        case PERMISSIONS_ACTION_TYPES.FETCH_REFRESH_USER_TOKEN.success:
        case CHANGE_CURRENT_USER:
            const { token, socketToken } = action.data;
            auth.setJwtToken(token);
            auth.setSocketJwtToken(socketToken);
            const user = auth.getUser();
            identifyFullStoryUser(user);
            return {
                ...state,
                user,
                isLoggedIn: true,
            };
        case SIGN_OUT:
        case SOCKET_CONNECTION_FAILED:
        case PERMISSIONS_ACTION_TYPES.FETCH_REFRESH_USER_TOKEN.failed: {
            auth.clearUser();
            return {
                ...state,
                user: {},
                isLoggedIn: false,
            };
        }
        case CHANGE_ALERT_PANE_STATE:
            return {
                ...state,
                isAlertPaneShown: action.isAlertPaneShown,
            };
        case PERMISSIONS_ACTION_TYPES.FETCH_PERMISSION.success: {
            return {
                ...state,
                permission: action.data.permission,
                isPermissionFetched: true,
            };
        }
        case FETCH_APP_SETTINGS.success:
            return {
                ...state,
                appSettings: action.data.settings?.config,
            };
        default:
            return state;
    }
};

export default app;
