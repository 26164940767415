import { FETCH_ACTION_TYPES } from '@beewise/react-utils';
import { isUndefined } from 'lodash-es';
import { CLEAR_OPERATION_ERRORS } from 'components/views/BeeHome/actionTypes';
import { SAVE_CURRENT_MAP_STATE, CLEAR_CURRENT_MAP_STATE } from 'components/views/Dashboard/actionTypes';
import {
    SHOW_ACTIVITY_BAR,
    SET_ENTRANCES_STATE,
    SET_FULL_SCREEN_IMAGE,
    TOGGLE_MAP,
    TOGGLE_INITIAL_RENDER,
    TOGGLE_RIGHT_PANEL,
} from 'actionTypes';

const initialState = {
    moveFramesError: null,
    dashboardMapState: {},
    showMap: false,
    initialRender: true,
    showRightPanel: false,
    entrancesState: [],
    loading: {},
    fetched: {},
    fullScreenImage: { image: null, side: null },
};

const ui = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTION_TYPES.LOADING_START:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.key]: true,
                },
                fetched: {
                    ...state.loading.fetched,
                    [action.key]: false,
                },
            };
        case FETCH_ACTION_TYPES.LOADING_END:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.key]: false,
                },
                fetched: {
                    ...state.loading.fetched,
                    [action.key]: true,
                },
            };
        case CLEAR_OPERATION_ERRORS:
            return {
                ...state,
                moveFramesError: null,
            };
        case SAVE_CURRENT_MAP_STATE:
            return {
                ...state,
                dashboardMapState: {
                    center: action.center,
                    zoom: action.zoom,
                },
            };
        case CLEAR_CURRENT_MAP_STATE:
            return {
                ...state,
                dashboardMapState: {},
            };
        case SHOW_ACTIVITY_BAR:
            return {
                ...state,
                showActivity: true,
            };
        case SET_ENTRANCES_STATE:
            return {
                ...state,
                entrancesState: action.entrances,
            };
        case SET_FULL_SCREEN_IMAGE:
            return {
                ...state,
                fullScreenImage: {
                    image: action.image,
                    side: action.side,
                },
            };
        case TOGGLE_MAP:
            return {
                ...state,
                showMap: isUndefined(action.isShown) ? !state.showMap : action.isShown,
            };
        case TOGGLE_INITIAL_RENDER:
            return {
                ...state,
                initialRender: isUndefined(action.isInitialRender) ? !state.initialRender : action.isInitialRender,
            };
        case TOGGLE_RIGHT_PANEL:
            return {
                ...state,
                showRightPanel: action.isShown,
            };
        default:
            return state;
    }
};

export default ui;
