import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import Tooltip from '@beewise/tooltip';

const COLORS = ['#D7E0F5', '#FFE6B0', '#FFC125'];

const Chart = ({ dataSource, graphLevel }) => {
    const names = [`0-${graphLevel.low}`, `${graphLevel.low + 1}-${graphLevel.medium}`, `${graphLevel.medium + 1}+`];

    const pieData = [
        {
            name: names[0],
            value: dataSource?.[0],
        },
        {
            name: names[1],
            value: dataSource?.[1],
        },
        {
            name: names[2],
            value: dataSource?.[2],
        },
    ];

    const total = dataSource?.reduce((acc, item) => acc + item, 0);

    if (isEmpty(dataSource)) {
        return null;
    }

    return (
        <Tooltip
            content={
                <div className="yard-graph-info">
                    <div className="yard-graph-info-item">
                        <div className="yard-graph-info-circle" style={{ background: COLORS[0] }} />
                        <div className="yard-graph-info-text">
                            <span>{names[0]}</span>
                        </div>
                    </div>
                    <div className="yard-graph-info-item">
                        <div className="yard-graph-info-circle" style={{ background: COLORS[1] }} />
                        <div className="yard-graph-info-text">
                            <span>{names[1]}</span>
                        </div>
                    </div>
                    <div className="yard-graph-info-item">
                        <div className="yard-graph-info-circle" style={{ background: COLORS[2] }} />
                        <div className="yard-graph-info-text">
                            <span>{names[2]}</span>
                        </div>
                    </div>
                </div>
            }
            className="yard-graph-tooltip"
            position="bottom center"
        >
            <div className="yard-graph">
                <div className="yard-graph-line">
                    {pieData.map((entry, index) => {
                        if (!entry?.value) {
                            return null;
                        }

                        return (
                            <div
                                key={`yard-graph-line${index}`}
                                className="yard-graph-line-item"
                                style={{
                                    width: `${((entry.value || 0) / (total || 1)) * 100}%`,
                                    backgroundColor: COLORS[index],
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </Tooltip>
    );
};

Chart.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.number),
    graphLevel: PropTypes.shape({
        low: PropTypes.number,
        medium: PropTypes.number,
    }),
};

export default Chart;
